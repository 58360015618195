import React from 'react';

const ManuButton = ({onClick}) => {
    
    return (
        <>
            <div className="d-flex flex-wrap justify-content-between m-b30 align-items-center">
                <div className="admin-left-area" onClick={onClick}>
                    <span className="nav-btn-admin d-flex justify-content-between align-items-center" id="sidebarCollapse">
                        <span className="nav-btn-text">Dashboard Menu</span>
                        <span className="fa fa-reorder"></span>
                    </span>
                </div>

                {/* <div class="admin-area-mid">
                    <div class="admin-area-heading">
                        <span>Your Tariff Plan : </span>
                        <strong>Extended <i class="fa fa-caret-down"></i></strong>
                    </div>
                    <div class="admin-area-content">you Are on Extended . Use link bellow to view details or upgrade.Details </div>

                </div> */}

                {/* <div class="admin-right-area">
                    <div class="pro-pic-info-wrap d-flex">
                        <div class="pro-pic-box">
                            <img src="images/user.jpg" alt="" />
                        </div>
                        <div class="pro-pic-info">
                            <strong>David Wood</strong>
                            <span>Designer</span>
                        </div>
                        <span class="feather-icon has-toltip">
                            <i class="feather-power"></i>
                            <span class="header-toltip">Notification</span>
                        </span>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default ManuButton;