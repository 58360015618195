import React, { useEffect, useState } from 'react';
import VendorSidebar from '../Provider/VendorSideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import lib from '../config/lib';
import Loading from '../components/Loading';
import ManuButton from './ManuButton';

function AdminCategoriesContent() {
    const [categoryImg, setCategoryImg] = useState(null);
    const [catname, setCatname] = useState('');
    const [catdescription, setCatdescription] = useState('');
    const [catid, setCatid] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleCatDisplay();
    }, []);

    const [data, setData] = useState([]);

    const handleCatDisplay = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getCatall, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleCatadd = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('categoryImg', categoryImg);
        formData.append('categoryname', catname);
        formData.append('categorydes', catdescription);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.catadd, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Categorie Added Successfully', 'success');
                handleCatDisplay();
                setLoading(false);
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            if (error.response.data.error === "Cannot read properties of undefined (reading '0')") {
                Swal.fire({
                    title: 'Failed',
                    text: 'Please Upload Image',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'Failed',
                    text: error.response.data.error,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        }
    }

    const handledataFeth = async (id) => {
        setLoading(true);
        setCatid(id);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getCatone + '/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setCatname(response.data.data.categoryname);
            setCatdescription(response.data.data.categorydes);
            setCategoryImg(response.data.data.categoryImg);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleEdittesting = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('categoryImg', categoryImg);
        formData.append('id', catid);
        formData.append('categoryname', catname);
        formData.append('categorydes', catdescription);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.catedit, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Categorie Updated Successfully', 'success');
                handleCatDisplay();
                handlesetdestroy();
                setLoading(false);
                const modal = document.getElementById('add_services');
                if (modal) {
                    modal.click();
                }
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            if (error.response.data.error === "Cannot read properties of undefined (reading '0')") {
                Swal.fire({
                    title: 'Failed',
                    text: 'Please Upload Image',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'Failed',
                    text: error.response.data.error,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        }
    }

    const handlesetdestroy = async () => {
        setCatid('');
    }


    const handleSearch = async (e) => {
        const search = e.target.value;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.catSearch + '/' + search, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
        }
    }

    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading} />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                <div className="content-admin-main">
                   <ManuButton onClick={handleManu}/>
                    <div className="aon-admin-heading">
                        <h4>My Categories</h4>
                    </div>
                    <div className="card aon-card">
                        <div className="card-body aon-card-body">
                            <div className="sf-bd-data-tb-head aon-mob-btn-marb">
                                <button className="admin-button m-l10" data-toggle="modal" data-target="#add_services" type="button">
                                    <i className="fa fa-plus"></i>
                                    Add a Category
                                </button>
                            </div>
                            <div className="table-responsive">
                                <div className='d-flex justify-content-end mb-3'>
                                    <div className='col-md-4'>
                                        <input type="text" className="form-control" placeholder="Search by Name" onChange={handleSearch} />
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item, index) => (
                                            <tr>
                                                <td className='w-25'><img src={lib.imgurl + '/' + item.categoryImg} className='w-100' />
                                                </td>

                                                <td>{item.categoryname}</td>
                                                <td>{item.categorydes}</td>
                                                {/* <td ><button className='btn btn-danger'>Delete</button></td> */}
                                                <td>
                                                    <div className="aon-mob-btn-marb">
                                                        <button className="admin-button" data-toggle="modal" data-target="#add_services" type="button" onClick={() => handledataFeth(item.id)}>
                                                            <i className="fa fa-plus"></i>
                                                            Edit Category
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div >

                    {/* <!--Modal add services-- > */}
                    <div className="modal fade content-admin-main" id="add_services" tabindex="-1" role="dialog" ariaHidden="true" >
                        <div className="modal-dialog model-w800" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {catid ? (
                                        <h5 className="modal-title" id="exampleModalLabel2">Update Categories</h5>
                                    ) : (
                                        <h5 className="modal-title" id="exampleModalLabel2">Add New Categories</h5>
                                    )}
                                    <button type="button" className="close" data-dismiss="modal" ariaLabel="Close">
                                        <span ariaHidden="true" onClick={handlesetdestroy}>&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="sf-md-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Category Image</label>
                                                    <div className="aon-inputicon-box">
                                                        <input type="file" name="avatar" accept="image/*" className='form-control sf-form-control' onChange={(e) => setCategoryImg(e.target.files[0])} />
                                                        <i className="aon-input-icon fa fa-camera"></i>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Category Name</label>
                                                    <div className="aon-inputicon-box">
                                                        <input className="form-control sf-form-control" value={catname} type="text" onChange={(e) => setCatname(e.target.value)} />
                                                        <i className="aon-input-icon fa fa-user"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label >Category Description</label>
                                                    <div className="aon-inputicon-box">
                                                        <input className="form-control sf-form-control" value={catdescription} type="text" onChange={(e) => setCatdescription(e.target.value)} />
                                                        <i className="aon-input-icon fa fa-comment"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {catid ? (
                                    <div className="modal-footer">
                                        <button type="button" className="admin-button" data-dismiss="modal" onClick={handlesetdestroy}>Cancel</button>
                                        <button type="button" className="admin-button" onClick={handleEdittesting} >Update</button>
                                    </div>
                                ) : (
                                    <div className="modal-footer">
                                        <button type="button" className="admin-button" data-dismiss="modal" onClick={handlesetdestroy}>Cancel</button>
                                        <button type="button" className="admin-button" onClick={handleCatadd} >Create</button>
                                    </div>
                                )}


                            </div>
                        </div>
                    </div >
                </div>
            </div>
        </>
    );
}

export default AdminCategoriesContent;