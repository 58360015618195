import React, { useState } from 'react'

const Loading = ({ loading } ) => {
    return (
        
        <div>
            {loading && (
                <div className="loading-area">
                    <div className="loading-box" />
                    <div className="loading-pic">
                        <div className="windows8">
                            <div className="wBall" id="wBall_1">
                                <div className="wInnerBall" />
                            </div>
                            <div className="wBall" id="wBall_2">
                                <div className="wInnerBall" />
                            </div>
                            <div className="wBall" id="wBall_3">
                                <div className="wInnerBall" />
                            </div>
                            <div className="wBall" id="wBall_4">
                                <div className="wInnerBall" />
                            </div>
                            <div className="wBall" id="wBall_5">
                                <div className="wInnerBall" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Loading;