import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading';
import lib from '../config/lib';
import Swal from 'sweetalert2';

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const router = useNavigate();

    const handleAdminLogin = async () => {
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminlogin, {
                "email": email,
                "pass": password,
            });
            if (response.data.status === "success") {
                // Swal.fire('Success', 'Admin Login Successfully', 'success');
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', response.data.role);
                router('/AdminDashboard');
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            Swal.fire({
                title: 'Failed',
                text: err.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div>
            {/* <!--Login Form--> */}
            <div className='pt-5'>
                <div className='pt-lg-5'>
                    <div className='col-md-12 d-flex justify-content-center'>
                        <div className='col-md-4 col-lg-3'>
                            <div className='col-md-12 text-center mb-4'><h3>Admin Login</h3></div>
                            <div id="Upcoming" className="tab-pane active">
                                <div className="sf-tabs-content">
                                    <div className="row">

                                        <Loading loading={loading}/>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" name="company_name" type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-user"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" name="company_name" type="text" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-lock"></i>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <Link to="/ForgptPasswordAdmin">Forgot Password</Link>
                                            </div>
                                        </div> */}
                                        <div className="col-md-12">
                                            <button type="submit" className="site-button w-100" onClick={handleAdminLogin}>Submit <i className="feather-arrow-right" ></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;