import React, { useEffect, useState } from 'react';
import VendorSidebar from './VendorSideBar';
import axios from 'axios';
import lib from '../config/lib';
import Loading from '../components/Loading';
import Swal from 'sweetalert2';

const ProviderAccount = () => {
    const [profileImg, setProfileImg] = useState(null);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pincoad, setPincoad] = useState('');

    const [loading, setLoading] = useState('');

    const [diaplay, setDiaplay] = useState(null);

    useEffect(() => {
        handleProviderOne();
    }, []);

    const handleProviderOne = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getOneprovider, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = response.data.data;

            setName(data.name);
            setEmail(data.email);
            setPhone(data.phone);
            setAddress(data.address);
            setCity(data.city);
            setState(data.state);
            setPincoad(data.pincoad);
            setCountry(data.country);
            setProfileImg(data.profileImg);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleUpdateProvider = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('address', address);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('pincoad', pincoad);
        formData.append('country', country);
        formData.append('profileImg', profileImg);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.providerUpdate, formData,{
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if(response.data.status === "success"){
                Swal.fire('Success', 'Provider Details Updated', 'success');
                setLoading(false);
                handleProviderOne();
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setProfileImg(file);
        setDiaplay(URL.createObjectURL(file));
    };

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading}/>
            <div id="content">
                <div>
                    <h3 className='ml-4'>You need to complete your profile</h3>
                </div>
                <div className="content-admin-main">
                    <div className="aon-admin-heading">
                        <h4>Edit Profile</h4>
                    </div>
                    <div className="card aon-card">
                        <div className="card-header aon-card-header"><h4><i className="fa fa-user"></i> About me</h4></div>
                        <div className="card-body aon-card-body">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="aon-staff-avtar">
                                        <div className="aon-staff-avtar-header">
                                            <div className="aon-pro-avtar-pic">
                                                {diaplay ? (
                                                    <img src={diaplay} alt="" />
                                                ) : (
                                                    <img src={lib.imgurl + '/' + profileImg} alt="" />
                                                )}
                                                
                                                <label className="admin-button has-tooltip">
                                                    <i className="fa fa-camera"></i>
                                                    <input type="file" name="avatar" accept="image/*" onChange={handleImageChange} />
                                                </label>
                                                <div className="header-tooltip pt-2" style={{ color: 'white' }}>Upload Avatar</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Name*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder='Providers Name' value={name} onChange={(e) => setName(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-user"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Mobile*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value) } />
                                                    <i className="aon-input-icon fa fa-phone"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Email Address*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-envelope"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Address*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder='Providers Address' value={address} onChange={(e) => setAddress(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-globe"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> Country*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder='Providers Country' value={country} onChange={(e) => setCountry(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-map-marker"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>State*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder='Providers State' value={state} onChange={(e) => setState(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-map-marker"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>City*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder='Providers City' value={city} onChange={(e) => setCity(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-map-marker"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> Postal Code*</label>
                                                <div className="aon-inputicon-box">
                                                    <input className="form-control sf-form-control" type="text" placeholder='Providers Postal Code' value={pincoad} onChange={(e) => setPincoad(e.target.value)} />
                                                    <i className="aon-input-icon fa fa-map-marker"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 text-right'>
                        <button className='btn btn-warning' onClick={handleUpdateProvider}>Update Profile</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProviderAccount;