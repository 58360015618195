import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import lib from '../config/lib';

function VendorSidebar() {
    const [menu, setMenu] = useState("");

    const router = useNavigate();

    const handlelogout = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminLogout, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                router('/AdminLogin');
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
        }
    }

    const handleProviderLogout = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.providerLogout, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                router('/ProviderLogin');
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
        }
    }

    useEffect(() => {
        checkfalidation();
    }, []);

    const checkfalidation = async () => {
        const role = localStorage.getItem('role');

        if (role === "superadmin") {
            setMenu("true");
        }
        else {
            setMenu("false");
        }
    }

    return (
        <>
            {menu === "true" ? (
                <nav id="sidebar-admin-wraper p-0">
                    <div className="pro-my-account-wrap">
                        Admin Dashboard
                    </div>
                    <div className="admin-nav" style={{ height: '100vh', overflow: "auto", '::--webkit-scrollbar': { width: '5px ' } }}>
                        <ul className="">
                            <li>
                                <Link to="/AdminDashboard" ><i className="fa fa-dashboard"></i><span className="admin-nav-text">Dashboard</span></Link>
                            </li>
                            <li>
                                <Link to="/AdminMyBooking" ><i className="fa fa-calendar"></i><span className="admin-nav-text">My Booking</span></Link>
                            </li>

                            <li>
                                <Link to="/AllCustomer"><i className="fa fa-users"></i><span className="admin-nav-text">All Customers</span></Link>
                            </li>
                            <li>
                                <Link to="/AllProvider"><i className="fa fa-users"></i><span className="admin-nav-text">All Providers</span></Link>
                            </li>
                            <li>
                                <Link to="/RegisterProvider"><i className="fa fa-users"></i><span className="admin-nav-text">Register providers list</span></Link>
                            </li>
                            <li>
                                <Link to="/Messages"><i className="fa fa-comments"></i><span className="admin-nav-text">Contact Messages</span></Link>
                            </li>
                            <li>
                                <Link to="/AdminCategory"><i className="fa fa-filter"></i><span className="admin-nav-text">Categories</span></Link>
                            </li>
                            <li>
                                <Link to="/ServiceArea" ><i className="fa fa-cogs"></i><span className="admin-nav-text">Service Area</span></Link>
                            </li>
                            <li>
                                <Link to="/ResetPassword" ><i className="fa fa-lock"></i><span className="admin-nav-text">Reset Password</span></Link>
                            </li>
                            <li>
                                <Link onClick={handlelogout}><i className="fa fa-lock"></i><span className="admin-nav-text" onClick={handlelogout}>Log out</span></Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            ) : (
                <nav id="sidebar-admin-wraper p-0">
                    <div className="pro-my-account-wrap">
                        Provider Dashboard
                    </div>
                    <div className="admin-nav" style={{ height: '100vh', overflow: "auto", '::--webkit-scrollbar': { width: '5px ' } }}>
                        <ul className="">
                            <li>
                                <Link to="/AdminMyBooking" ><i className="fa fa-calendar"></i><span className="admin-nav-text">My Booking</span></Link>
                            </li>
                            <li>
                                <Link to="/Services" ><i className="fa fa-cogs"></i><span className="admin-nav-text">My Services</span></Link>
                            </li>

                            <li>
                                <Link to="/ProviderAccount"><i className="fa fa-users"></i><span className="admin-nav-text">My Profile</span></Link>
                            </li>
                            
                            <li>
                                <Link to="/ResetPassword" ><i className="fa fa-lock"></i><span className="admin-nav-text">Reset Password</span></Link>
                            </li>

                            <li>
                                <Link onClick={handleProviderLogout}><i className="fa fa-lock"></i><span className="admin-nav-text" >Log out</span></Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            )}

        </>
    );
}

export default VendorSidebar;