import React, { useEffect, useState } from 'react';
import VendorSidebar from '../Provider/VendorSideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import lib from '../config/lib';
import Loading from '../components/Loading';
import ManuButton from './ManuButton';

function ServiceArea() {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState('');
    const [catname, setCatname] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        handleServiceAll();
    }, []);

    const handleServiceAll = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.serviceAreaAll, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleAddservicearea = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.serviceArea, {
                "servicearea": catname,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.data.status === "success") {
                Swal.fire('Success', 'Service area created successfully.', 'success');
                handleServiceAll();
                const modal = document.getElementById('add_services');
                if (modal) {
                    modal.click();
                }
                setLoading(false);
            }
        }
        catch (error) {
            Swal.fire({
                title: 'Failed',
                text: error.response.data.msg,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
        }
    }

    const handleServiceareaupdate = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.serviceAreaEdit, {
                "servicearea": catname,
                "id": id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response.data.status === "success") {
                Swal.fire('Success', 'Service area created successfully.', 'success');
                handleServiceAll();
                const modal = document.getElementById('add_services');
                if (modal) {
                    modal.click();
                }
                setLoading(false);
            }
        }
        catch (error) {
            Swal.fire({
                title: 'Failed',
                text: error.response.data.msg,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
        }
    }

    const handleIdblanck = async () => {
        setId('');
    }

    const handlesetID = async (id) => {
        setId(id);

        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.serviceAreafindone + '/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setCatname(response.data.data.servicearea);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading} />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                <div className="content-admin-main">
                    <ManuButton onClick={handleManu}/>
                    <div className="aon-admin-heading">
                        <h4>Service Area</h4>
                    </div>
                    <div className="card aon-card">
                        <div className="card-body aon-card-body">
                            <div className="sf-bd-data-tb-head aon-mob-btn-marb">
                                <button className="admin-button m-l10" data-toggle="modal" data-target="#add_services" type="button">
                                    <i className="fa fa-plus"></i>
                                    Add Area
                                </button>
                            </div>
                            <div className="table-responsive">
                                <div className='d-flex justify-content-end mb-3'>
                                    {/* <div className='col-md-4'>
                                        <input type="text" className="form-control" placeholder="Search by area name" />
                                    </div> */}
                                </div>
                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Sr.no</th>
                                            <th>Area Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.servicearea}</td>
                                                <td>
                                                    <div className="aon-mob-btn-marb">
                                                        <button className="admin-button" data-toggle="modal" data-target="#add_services" type="button" onClick={() => handlesetID(item.id)}>
                                                            <i className="fa fa-plus"></i>
                                                            Edit Area
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div >

                    <div className="modal fade content-admin-main" id="add_services" tabindex="-1" role="dialog" ariaHidden="true" >
                        <div className="modal-dialog model-w800" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {id ? (
                                        <h5 className="modal-title" id="exampleModalLabel2">Edit Service Area</h5>
                                    ) : (
                                        <h5 className="modal-title" id="exampleModalLabel2">Add Service Area</h5>
                                    )}
                                    <button type="button" className="close" data-dismiss="modal" ariaLabel="Close">
                                        <span ariaHidden="true" onClick={handleIdblanck} >&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="sf-md-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Category Name</label>
                                                    <input className="form-control sf-form-control" placeholder='Enter service area' type="text" value={catname} onChange={(e) => setCatname(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {id ? (
                                    <div className="modal-footer">
                                        <button type="button" className="admin-button" onClick={handleServiceareaupdate}>Update</button>
                                    </div>
                                ) : (
                                    <div className="modal-footer">
                                        <button type="button" className="admin-button" onClick={handleAddservicearea}>Create</button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceArea;