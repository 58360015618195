import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Loading from '../components/Loading';
import Swal from 'sweetalert2';
import axios from 'axios';
import lib from '../config/lib';

function ForgotPassword() {
    const [loading, setLoading] = useState('');
    const [otpsend, setOtpsend] = useState(false);

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [np, setNp] = useState('');
    const [cp, setCp] = useState('');

    const handleSendotp = async () => {
        setLoading(true);
        try{
            const response = await axios.post(lib.apiURL + '/' + lib.apis.providersendotp, {
                "email": email,
            });
            if(response.data.status === "success"){
                Swal.fire('success', 'Otp send successfully', 'success');
                setOtpsend("true");
                setLoading(false);
            }
        }
        catch(error){
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleVerifyotp = async () =>{
        setLoading(true);

        try{
            const response = await axios.post(lib.apiURL + '/' + lib.apis.providerVerifyotp, {
                "email": email,
                "otp": otp,
                "np": np,
                "cp": cp
            });
            if(response.data.status === "success"){
                Swal.fire('success', 'Password Changed Successfully', 'success');
                setLoading(false);
                setOtpsend("false");
            }
        }
        catch(error){
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);   
        }
    }

    return (
        <>
            <Header />
            <Loading loading={loading} />
            <div className='p-5'>
                <div className='pt-lg-5'>
                    <div className='col-md-12 d-flex justify-content-center'>
                        <div className='col-md-4 col-lg-3'>
                            <div className='col-md-12 text-center mb-4'><h3>Create Password</h3></div>
                            <div id="Upcoming" className="tab-pane active">
                                <div className="sf-tabs-content">
                                    <div className="row">

                                        {otpsend === "true" ? (
                                            <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Otp</label>
                                                        <input className="form-control sf-form-control" name="company_name" type="number" placeholder="Otp" onChange={(e) => setOtp(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <input className="form-control sf-form-control" name="company_name" type="Password" placeholder="Password" onChange={(e) => setNp(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <input className="form-control sf-form-control" name="company_name" type="password" placeholder="Confirm password" onChange={(e) => setCp(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit" className="site-button w-100" onClick={handleVerifyotp}>Submit <i className="feather-arrow-right" ></i> </button>
                                                    <div className='text-center mt-3'>
                                                        <Link to="/ProviderLogin">Already Known ? Login</Link>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input className="form-control sf-form-control" name="company_name" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <button type="submit" className="site-button w-100" onClick={handleSendotp}>Send Otp <i className="feather-arrow-right" ></i> </button>
                                                    <div className='text-center mt-3'>
                                                        <Link to="/ProviderLogin">Already Known ? Login</Link>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default ForgotPassword;