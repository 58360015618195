import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import Main from './Main';
import UserProfile from './components/mc-profile'
import ProvierLogin from "./Auth/ProviderLogin";
import AdminLogin from "./Auth/AdminLogin";
// import Error from "./Error";
import ProviderSignUp from "./Auth/ProviderSignup";
import ForgptPassword from "./Auth/ForgptPassword";
import AllCategoryDetaile from './components/all-categories'
import ProfileFull from "./components/profile-full";
import CategoriesDetail from "./components/categories-detail";
import ContactUs from "./components/contact-us";
import AdminDashboard from "./AdminPages/AdminDashboard";
import AdminSideBar from "./AdminPages/AdminSideBar";
import ProviderAccount from "./Provider/ProvierAccount";
import ResetPasswordContent from "./Provider/ResetPasswordContent";
import MyBookingsAdmin from "./AdminPages/MyBookings";
import AllProvider from "./AdminPages/AllProviders";
import AllCustomer from "./AdminPages/AllCustomer";
import RegisterProvider from "./AdminPages/RegisterProviders";
import AllMessages from "./AdminPages/Messages";
import AdminCategory from "./AdminPages/AdminCategory";
import AdminServices from "./AdminPages/Services";
import ResetPasswordAdmin from "./AdminPages/ResetPasswordAdmin";
import ForgptPasswordAdmin from "./Auth/ForgptPasswordAdmin";
import JobDetails from "./components/jobdetails";
import MyCart from "./components/cart";
import CutomerBookings from "./components/CutomerBookings";
import ForgotPasswordCustomer from "./Auth/ForgptPasswordCustomer";
import McAvailability from "./components/mc-availability";
import CheckoutScreen from "./components/checkout";
import ServiceArea from "./AdminPages/ServiceArea";


const App = () => {
    const router = useNavigate();

    useEffect(() => {
      const token = localStorage.getItem('token');
      const exemptedRoutes = ['/', '/ContactUs', '/ProviderLogin', '/ProviderSignUp', '/AdminLogin', '/CategoriesDetail', '/AllCategoryDetaile', '/ProfileFull', '/jobdetails'];

      if (!token && !exemptedRoutes.includes(window.location.pathname)) {
        router('/');
      }
    }, [router]);

  return (
    <Routes>
      <Route path='/' element={<Main />} />
      <Route path='/ProviderLogin' element={<ProvierLogin />} />
      <Route path='/AdminLogin' element={<AdminLogin />} />
      <Route path='/ForgptPassword' element={<ForgptPassword />} />
      {/* <Route path='/Error' element={<Error />} /> */}
      <Route path='/ProviderSignUp' element={<ProviderSignUp />} />
      <Route path='/UserProfile' element={<UserProfile />} />
      <Route path='/AllCategoryDetaile' element={<AllCategoryDetaile />} />
      <Route path='/ProfileFull' element={<ProfileFull />} />
      <Route path='/CategoriesDetail' element={<CategoriesDetail />} />
      <Route path='/ContactUs' element={<ContactUs />} />
      <Route path='/AdminDashboard' element={<AdminDashboard />} />
      <Route path='/AdminSideBar' element={<AdminSideBar />} />
      <Route path='/AdminMyBooking' element={<MyBookingsAdmin />} />
      <Route path='/AllProvider' element={<AllProvider />} />
      <Route path='/AllCustomer' element={<AllCustomer />} />
      <Route path='/Messages' element={<AllMessages />} />
      <Route path='/RegisterProvider' element={<RegisterProvider />} />
      <Route path='/AdminCategory' element={<AdminCategory />} />
      <Route path='/Services' element={<AdminServices />} />
      <Route path='/ResetPassword' element={<ResetPasswordAdmin />} />
      <Route path='/ForgptPasswordAdmin' element={<ForgptPasswordAdmin />} />
      <Route path='/ProviderAccount' element={<ProviderAccount />} />
      <Route path='/ResetPasswordContent' element={<ResetPasswordContent />} />
      <Route path='/jobdetails' element={<JobDetails />} />
      <Route path='/mycart' element={<MyCart />} />
      <Route path='/mybooking' element={<CutomerBookings />} />
      <Route path='/availability' element={<McAvailability/>}/>
      <Route path='/checkout' element={<CheckoutScreen/>}/>
      <Route path='/ServiceArea' element={<ServiceArea/>}/>
      <Route path='/forgotpasswordcustomer' element={<ForgotPasswordCustomer />} />
    </Routes>
  )
};

export default App;