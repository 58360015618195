import React, { useState, useEffect } from 'react';
import VendorSidebar from '../Provider/VendorSideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import lib from '../config/lib';
import Loading from '../components/Loading';
import handleVerify from '../config/accountvalidation';
import { useNavigate } from 'react-router-dom';
import ManuButton from './ManuButton';

const ResetPassword = () => {
    const [np, setNp] = useState('');
    const [cp, setCp] = useState('');

    const [menu, setMenu] = useState('');

    const [loading, setLoading] = useState(false);

    const handlePassword = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminchangepass, {
                "np": np,
                "cp": cp,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Password Set Successfully', 'success');
                setLoading(false);
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleProviderpass = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminchangepass, {
                "np": np,
                "cp": cp,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Password Set Successfully', 'success');
                setLoading(false);
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const router = useNavigate();

    useEffect(() => {
        checkfalidation();
        handleVerify(router);
    }, []);

    const checkfalidation = async () => {
        const role = localStorage.getItem('role');

        if (role === "superadmin") {
            setMenu("true");
        }
        else {
            setMenu("false");
        }
    }

    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading} />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                <div class="content-admin-main">
                    <ManuButton onClick={handleManu}/>
                    <div class="card aon-card" id="aon-passUpdate-panel">
                        <div class="card-header aon-card-header"><h4><i class="fa fa-lock"></i> Password Update</h4></div>
                        <div class="card-body aon-card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>New Password</label>
                                        <div class="aon-inputicon-box">
                                            <input class="form-control sf-form-control" type="text" onChange={(e) => setNp(e.target.value)} />
                                            <i class="aon-input-icon fa fa-lock"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Repeat Password</label>
                                        <div class="aon-inputicon-box">
                                            <input class="form-control sf-form-control" type="text" onChange={(e) => setCp(e.target.value)} />
                                            <i class="aon-input-icon fa fa-lock"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {menu === "true" ? (
                        <div className='col-md-12 text-right'>
                            <button className='btn btn-warning' onClick={handlePassword}>Update Password</button>
                        </div>
                    ) : (
                        <div className='col-md-12 text-right'>
                            <button className='btn btn-warning' onClick={handleProviderpass}>Update Password</button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ResetPassword;