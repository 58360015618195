import React, { useEffect, useState } from 'react';
import VendorSidebar from '../Provider/VendorSideBar';
import Loading from '../components/Loading';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';
import ManuButton from './ManuButton';

function AllMessages() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [serviceid, setserviceId] = useState('');

    useEffect(() => {
        handleFindone();
    }, []);

    const handleFindone = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getAllcontactus, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setLoading(false);
            setData(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handlesetid = async (id) => {
        setserviceId(id);
    }

    const handleDelete = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deleteContactus, {
                "id": serviceid,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Service deleted successfully', 'success');
                handleFindone();
                setLoading(false);
                const modal = document.getElementById('Delete_services');
                if (modal) {
                    modal.click();
                }
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleSearch = async (e) => {
        const search = e.target.value;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.contactusSearch + '/' + search, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
        }
    }

    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading} />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                <div class="content-admin-main">
                    <ManuButton onClick={handleManu}/>
                    <div className="aon-admin-heading">
                        <h4>Contact Messages</h4>
                    </div>
                    <div class="card aon-card">
                        <div class="card-body aon-card-body">
                            <div class="sf-bs-data-table">
                                <div class="table-responsive">
                                    <div className='d-flex justify-content-end mb-3'>
                                        <div className='col-md-3'>
                                            <input type="text" className="form-control" placeholder="Search by Name" onChange={handleSearch}/>
                                        </div>
                                    </div>
                                    <table class="table table-striped table-bordered" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {data && data.map((item, index) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.subject}</td>
                                                        <td>{item.message}</td>
                                                        <td><button className='btn btn-danger' data-toggle="modal" data-target="#Delete_services" onClick={() => handlesetid(item.id)}>Delete</button></td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* < !--Modal add group-- > */}
            <div class="modal fade content-admin-main" id="addteammembers" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog model-w800" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Team Members</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div >
                </div >
            </div>

            {/* <>Delete Modle Starts hear</> */}
            <div className="modal fade content-admin-main" id="Delete_services" tabindex="-1" role="dialog" ariaHidden="true" >
                <div className="modal-dialog model-w800" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel2">Delete Modle</h5>
                            <button type="button" className="close" data-dismiss="modal" ariaLabel="Close">
                                <span ariaHidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="sf-md-padding">
                                <div className="row">
                                    <h5 className="modal-title ml-100" id="exampleModalLabel2">Are you sure you want to delete it...</h5>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="admin-button" data-dismiss="modal">No</button>
                            <button type="button" className="admin-button" onClick={handleDelete}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllMessages;