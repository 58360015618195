import React from 'react';
import AdminSideBar from '../AdminPages/AdminSideBar';

const ResetPasswordContent = () => {

    return (
        <>
        <AdminSideBar/>
            <div id="content">
                <div class="content-admin-main">
                    <div class="card aon-card" id="aon-passUpdate-panel">
                        <div class="card-header aon-card-header"><h4><i class="fa fa-lock"></i> Password Update</h4></div>
                        <div class="card-body aon-card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>New Password</label>
                                        <div class="aon-inputicon-box">
                                            <input class="form-control sf-form-control" name="company_name" type="text" />
                                            <i class="aon-input-icon fa fa-lock"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Repeat Password</label>
                                        <div class="aon-inputicon-box">
                                            <input class="form-control sf-form-control" name="company_name" type="text" />
                                            <i class="aon-input-icon fa fa-lock"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 text-right'>
                        <button className='btn btn-warning'>Update Password</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPasswordContent;