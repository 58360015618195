import React, { useEffect, useState } from 'react';
import VendorSidebar from '../Provider/VendorSideBar';
import axios from 'axios';
import lib from '../config/lib';
import Loading from '../components/Loading';
import Swal from 'sweetalert2';
import ManuButton from './ManuButton';

function AllProvider() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState('');

    useEffect(() => {
        handleProviderall();
    }, []);

    const handleProviderall = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getAllprovider + '/' + 'approve', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleReject = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.providerstatusupdate, {
                "id": id,
                "status": "pending"
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Reqected successfully', 'success');
                handleProviderall();
            }
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleSearch = async (e) => {
        const search = e.target.value;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.providerSearch + '/' + search, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
        }
    }

    const handleFeaturedprovider = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.favProvider, {
                "id": id,
                "featured": "true",
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Provider add to main page', 'success');
                handleProviderall();
            }
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }


    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading} />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                <div class="content-admin-main">
                    <ManuButton onClick={handleManu}/>
                    <div className="aon-admin-heading">
                        <h4>All Active Providers</h4>
                    </div>
                    <div class="card aon-card">
                        <div class="card-body aon-card-body">
                            <div class="sf-bs-data-table">
                                <div class="table-responsive">
                                    <div className='d-flex justify-content-end mb-3'>
                                        <div className='col-md-3'>
                                            <input type="text" className="form-control" placeholder="Search by Name" onClick={handleSearch} />
                                        </div>
                                    </div>
                                    <table class="table table-striped table-bordered" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Profile Pic</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th>Address</th>
                                                <th>Pincode</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {data && data.map((item, index) => (
                                                    <tr>
                                                        <td className='p-2'><img src={lib.imgurl + '/' + item.profileImg} className=' w-25' alt="" /></td>
                                                        <td>{item.name}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.city}</td>
                                                        <td>{item.state}</td>
                                                        <td>{item.country}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.pincoad}</td>
                                                        <td>
                                                            <button className='btn btn-danger' onClick={() => handleReject(item.id)}>Reject</button>
                                                            <button className='btn btn-warning' onClick={() => handleFeaturedprovider(item.id)}>Featured</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* < !--Modal add group-- > */}
            <div class="modal fade content-admin-main" id="addteammembers" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog model-w800" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Team Members</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllProvider;