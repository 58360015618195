import VendorSidebar from '../Provider/VendorSideBar';
import React, { useEffect, useState } from 'react';
import Loading from '../components/Loading';
// import VendorSidebar from '../Provider/VendorSideBar';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';
import ManuButton from './ManuButton';

function RegisterProvider() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState('');

    useEffect(() => {
        handleProviderall();
    }, []);

    const handleProviderall = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getAllprovider + '/' + 'pending', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleAccept = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.providerstatusupdate,{
                "id": id,
                "status": "approve"
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if(response.data.status === "success"){
                Swal.fire('Success', 'Service deleted successfully', 'success');
                handleProviderall();
            }
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleReject = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.providerstatusupdate,{
                "id": id,
                "status": "pending"
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if(response.data.status === "success"){
                Swal.fire('Success', 'Service deleted successfully', 'success');
                handleProviderall();
            }
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleSearch = async (e) => {
        const search = e.target.value;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.providerSearch + '/' + search, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
        }
    }
    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading} />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                <div class="content-admin-main">
                    <ManuButton onClick={handleManu}/>
                    <div className="aon-admin-heading">
                        <h4>New Registered Providers</h4>
                    </div>
                    <div class="card aon-card">
                        <div class="card-body aon-card-body">
                            <div class="sf-bs-data-table">
                                <div class="table-responsive">
                                    <div className='d-flex justify-content-end mb-3'>
                                        <div className='col-md-3'>
                                            <input type="text" className="form-control" placeholder="Search by Name" onChange={handleSearch} />
                                        </div>
                                    </div>
                                    <table class="table table-striped table-bordered" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Document 1</th>
                                                <th>Document 2</th>
                                                <th>Status</th>
                                                <th>Accept</th>
                                                <th>Reject</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {data && data.map((item, index) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.email}</td>
                                                        <td className="text-center"><a href={lib.imgurl + '/' + item.doc1} target='_blank'>view<i class="fa fa-eye ml-2"></i></a></td>
                                                        <td className="text-center"><a href={lib.imgurl + '/' + item.doc2} target='_blank'>view<i class="fa fa-eye ml-2"></i></a></td>
                                                        <td>{item.status}</td>
                                                        <td ><button className='btn btn-success' onClick={() => handleAccept(item.id)}>Accept</button></td>
                                                        <td ><button className='btn btn-danger' onClick={() => handleReject(item.id)}>Reject</button></td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default RegisterProvider;