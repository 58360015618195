import React, { useEffect, useState } from 'react'
import Loading from './Loading'
import Header from './Header'
import Footer from './Footer'
import axios from 'axios'
import lib from '../config/lib'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router'

const UserProfile = () => {
    const [loading, setLoading] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [emailaddress, setEmailaddress] = useState('');
    const [address, setAddress] = useState('');

    const router = useNavigate();
    
    useEffect(() => {
        handleUserprofile();
    }, []);

    const handleUserprofile = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getUserone, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = response.data.data;

            setFirstname(data.firstName);
            setLastname(data.lastName);
            setEmailaddress(data.email);
            setMobile(data.phone);
            setAddress(data.address);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleEditprofile = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userUpdate, {
                "firstName": firstname,
                "lastName": lastname,
                "email": emailaddress,
                "phone": mobile,
                "address": address
            },{
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if(response.data.status === "success"){
                Swal.fire('success', 'Profile Updated successfully', 'success');
                setLoading(false);
                router('/');
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="page-wraper bg-color">
                <Header />
                <Loading loading={loading} />
                <div className='container'>
                    <div className="content-admin-main">
                        <div className="aon-admin-heading">
                            <h4>Edit Profile</h4>
                        </div>
                        <div className="card aon-card" id="aon-contact-panel">
                            <div className="card-header aon-card-header"><h4><i className="fa fa-envelope" />My Profile</h4></div>
                            <div className="card-body aon-card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <div className="aon-inputicon-box">
                                                <input className="form-control sf-form-control" type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                                                <i className="aon-input-icon fa fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <div className="aon-inputicon-box">
                                                <input className="form-control sf-form-control" type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                                <i className="aon-input-icon fa fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <div className="aon-inputicon-box">
                                                <input className="form-control sf-form-control" type="text" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                                <i className="aon-input-icon fa fa-phone" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <div className="aon-inputicon-box">
                                                <input className="form-control sf-form-control" type="email" value={emailaddress} onChange={(e) => setEmailaddress(e.target.value)} />
                                                <i className="aon-input-icon fa fa-envelope" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <div className="aon-inputicon-box">
                                                <input className="form-control sf-form-control" type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                                                <i className="aon-input-icon fa fa-envelope" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type="button" className="site-button" onClick={handleEditprofile}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default UserProfile