import React from 'react';
import { Link } from 'react-router-dom';

function AdminSideBar() {
    return (
        <>
            {/* <!-- Sidebar Holder --> */}
            <nav id="sidebar-admin-wraper p-0">
                <div class="pro-my-account-wrap">
                    Vender My Account
                </div>
                <div class="admin-nav" style={{ height: '100vh', overflow: "auto", '::--webkit-scrollbar': { width: '5px ' } }}>
                    <ul class="">
                        <li class="active">
                            <Link to="/vendor"><i class="fa fa-dashboard"></i><span class="admin-nav-text">Dashboard</span></Link>
                        </li>
                        <li>
                            <Link to={`/vendorProfile/${localStorage.getItem('providerId')}`}><i class="fa fa-user-circle-o"></i><span class="admin-nav-text">Profile</span></Link>
                        </li>
                        <li>
                            <Link to="/vendorFindJob" ><i class="fa fa-search"></i><span class="admin-nav-text">Find Job</span></Link>
                        </li>
                        <li>
                            <Link to="/vendorMyBookings" ><i class="fa fa-search"></i><span class="admin-nav-text">My Jobs</span></Link>
                        </li>
                        <li>
                            <Link to="/ResetPasswordContent" ><i class="fa fa-lock"></i><span class="admin-nav-text">Reset Password</span></Link>
                        </li>
                    </ul>
                </div >
            </nav >
        </>
    );
}

export default AdminSideBar;