const lib = {
    apiURL: 'https://quickgrowtech.in/thetracebook/api',
    imgurl: 'https://quickgrowtech.in/thetracebook',


    apis: {
        "login":"login",
        "signup":"signup",
        "providerlogin":"providerlogin",
        "providersignup": "providersignup",
        "adminlogin":"adminlogin",
        "adminchangepass": "adminchangepass",
        "catadd":"catadd",
        "getCatall":"getCatall",
        "getCatone":"getCatone",
        "catedit":"catedit",
        "serviceadd":"serviceadd",
        "getServiceall":"getServiceall",
        "deleteservice":"deleteservice",
        "serviceedit":"serviceedit",
        "getServiceone": "getServiceone",
        "getAllcontactus": "getAllcontactus",
        "deleteContactus": "deleteContactus",
        "getuserall": "getuserall",
        "deleteuser": "deleteuser",
        "userSearch": "userSearch",
        "contactusSearch": "contactusSearch",
        "serviceSearch": "serviceSearch",
        "catSearch": "catSearch",
        "getAllprovider": "getAllprovider",
        "providerstatusupdate": "providerstatusupdate",
        "providerSearch": "providerSearch",
        "adminlogin": "adminlogin",
        "getOneprovider": "getOneprovider",
        "providerUpdate": "providerUpdate",
        "adminLogout": "adminLogout",
        "getCatallhome": "getCatallhome",
        "getServicehome": "getServicehome",
        "serviceAreaAll": "serviceAreaAll",
        "providerLogout": "providerLogout",
        "getProviderbyid": "getProviderbyid",
        "getserviceBylocation": "getserviceBylocation",
        "serviceAreaAllhome": "serviceAreaAllhome",
        "providerFav": "providerFav",
        "getAllproviderHome": "getAllproviderHome",
        "serviceAreaAllhome": "serviceAreaAllhome",
        "getuserallHome": "getuserallHome",
        "contactus": "contactus",
        "serviceAreaAll": "serviceAreaAll",
        "serviceArea": "serviceArea",
        "serviceAreaEdit": "serviceAreaEdit",
        "serviceAreafindone": "serviceAreafindone",
        "favProvider": "favProvider",
        "getServiceoneHome": "getServiceoneHome",
        "addressupdateoncheckout": "addressupdateoncheckout",
        "getServicebyprovider": "getServicebyprovider",
        "sendotp": "sendotp",
        "verifyotp": "verifyotp",
        "providersendotp": "providersendotp",
        "providerVerifyotp": "providerVerifyotp",
        "getUserone": "getUserone",
        "userUpdate": "userUpdate",
        "logout": "logout",
        "handlebooking": "handlebooking",
        "getBookingbycustomer": "getBookingbycustomer",
        "getBookingbyprovider": "getBookingbyprovider",
        "getAllbookings": "getAllbookings",
        "getApproved": "getApproved",
        "getUpdatepayment": "getUpdatepayment",
    }
};

export default lib;