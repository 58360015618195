import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';

function ForgotPasswordCustomer() {
    const [otpsend, setOtpsend] = useState("false");
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const [otp, setOtp] = useState('');
    const [np, setNp] = useState('');
    const [cp, setCp] = useState('');

    const handleSendotp = async () => {
        setLoading(true);
        try{
            const response = await axios.post(lib.apiURL + '/' + lib.apis.sendotp, {
                "email": email,
            });
            if(response.data.status === "success"){
                Swal.fire('success', 'Otp Send Successfully', 'success');
                setOtpsend("true");
                setLoading(false);
            }
        }
        catch(error){
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleVerifyotp = async () => {
        setLoading(true);
        
        if(np === cp){
            try{
                const response = await axios.post(lib.apiURL + '/' + lib.apis.verifyotp, {
                    "email": email,
                    "otp": otp,
                    "np": np,
                    "cp": cp,
                });
                if(response.data.status === "success"){
                    Swal.fire('success', 'Password Changed Successfully', 'success');
                    setOtpsend("false");
                    setLoading(false);
                }
            }
            catch(error){
                Swal.fire({
                    title: 'Failed',
                    text: error.response.data.error,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        }
        else{
            Swal.fire('failed', 'New Password and Conform password not mached', 'error');
            setLoading(false);
        }
    }

 
    return (
        <>
            <Header />
            <Loading loading={loading}/>
            <div className='p-5'>
                <div className='pt-lg-5'>
                    <div className='col-md-12 d-flex justify-content-center'>
                        <div className='col-md-4 col-lg-3'>
                            <div className='col-md-12 text-center mb-4'><h3>Create Password</h3></div>
                            <div id="Upcoming" className="tab-pane active">
                                <div className="sf-tabs-content">
                                    <div className="row">

                                        {otpsend === "true" ? (
                                            <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Otp</label>
                                                        <input className="form-control sf-form-control" type="number" placeholder="Number" onChange={(e) => setOtp(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <input className="form-control sf-form-control" type="Password" placeholder="Password" onChange={(e) => setNp(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <input className="form-control sf-form-control" type="password" placeholder="Confirm password" onChange={(e) => setCp(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit" className="site-button w-100" onClick={handleVerifyotp}>Submit <i className="feather-arrow-right" ></i> </button>
                                                    <div className='text-center mt-3'>
                                                        <Link to="/">Already Known ? Login</Link>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input className="form-control sf-form-control" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit" className="site-button w-100" onClick={handleSendotp}>Send Otp <i className="feather-arrow-right"></i> </button>
                                                    <div className='text-center mt-3'>
                                                        <Link to="/">Already Known ? Login</Link>
                                                    </div>
                                                </div>
                                            </>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default ForgotPasswordCustomer;