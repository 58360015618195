import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import lib from "./lib";

// const handleVerify = (navigate, handleProviderOne) => {
//     const role = localStorage.getItem('role');
//     const accstatus = localStorage.getItem('accstatus');

//     if(role === "emp"){
//         handleProviderOne(navigate);
//     }
// }


const handleVerify = async (navigate) => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    try {
        const response = await axios.get(lib.apiURL + '/' + lib.apis.getOneprovider, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if(role === "emp"){
            if(response.data.data.accstatus === "pending"){
                navigate('/ProviderAccount');
            }
        }
    }
    catch (error) {
        console.log('Somthing Went Wrong');
        
    }
}


export default handleVerify;