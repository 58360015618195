import React, { useState } from 'react';
import VendorSideBar from '../Provider/VendorSideBar'
import ManuButton from './ManuButton';

function AdminDashboard() {

    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    return (
        <>
            <VendorSideBar />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                <div class="content-admin-main">
                    <ManuButton onClick={handleManu}/>
                    <div class="row">
                        <div class="col-xl-8 col-lg-12 m-b30 break-1300">
                            <div class="card aon-card">
                                <div class="card-body aon-card-body">
                                    <div class="row">
                                        <div class="col-lg-4 m-b30">
                                            <div class="panel panel-default ser-card-default">
                                                <div class="panel-body ser-card-body ser-orange p-a30">
                                                    <div class="ser-card-title">Booking</div>
                                                    <div class="ser-card-icons"><img src="images/booking.png" alt="" /></div>
                                                    <div class="ser-card-amount">5</div>
                                                    <div class="ser-card-table">
                                                        <div class="ser-card-left">
                                                            <div class="ser-card-total">
                                                                <div class="ser-total-table">
                                                                    <div class="ser-total-cell1">Wallet</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="ser-card-right">
                                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-wallet"></i></div>
                                                        </div>
                                                    </div>
                                                    <span class="ser-card-circle-bg"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 m-b30">
                                            <div class="panel panel-default ser-card-default">
                                                <div class="panel-body ser-card-body ser-blue p-a30">
                                                    <div class="ser-card-title">Earning</div>
                                                    <div class="ser-card-icons"><img src="images/earning.png" alt="" /></div>
                                                    <div className="ser-card-amount">
                                                        10
                                                    </div>
                                                    <div class="ser-card-table">
                                                        <div class="ser-card-left">
                                                            <div class="ser-card-total">
                                                                <div class="ser-total-table">
                                                                    <div class="ser-total-cell1">Complete</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="ser-card-right">
                                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-calendar"></i></div>
                                                        </div>
                                                    </div>
                                                    <span class="ser-card-circle-bg"></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-xl-4">
                            <div class="card aon-card">
                                <div class="card-header aon-card-header aon-card-header2"><h4><i class="feather-pie-chart"></i> Booking Stats</h4></div>
                                <div class="card-body aon-card-body">
                                    <div>
                                        <ul class="list-unstyled">
                                            <li class="py-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="avatar-xs rounded-circle m-r10">
                                                        <i class="feather-check-circle"></i>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="text-muted mb-2">Completed</p>
                                                        <div class="progress progress-sm animated-progess">
                                                            <div class="progress-bar bg-warning" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="py-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="avatar-xs rounded-circle m-r10">
                                                        <i class="feather-calendar"></i>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <p class="text-muted mb-2">Pending</p>
                                                        <div class="progress progress-sm animated-progess">
                                                            <div class="progress-bar bg-warning" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr />
                                    <div class="text-center">
                                        <div class="row aon-states-row">
                                            <div class="col-4">
                                                <div class="mt-2">
                                                    <p class="text-muted mb-2">Completed</p>
                                                    <h5 class="font-size-16 mb-0">0</h5>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="mt-2">
                                                    <p class="text-muted mb-2">Pending</p>
                                                    <h5 class="font-size-16 mb-0">5</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
}

export default AdminDashboard;