import React, { useState } from 'react'
import Loading from './Loading'
import Header from './Header'
import LoginModal from './LoginModal'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import lib from '../config/lib'
import Swal from 'sweetalert2'

const ContactUs = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleContactus = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.contactus, {
                "name": name,
                "email": email,
                "phone": phone,
                "subject": subject,
                "message": message
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if(response.data.status === "success"){
                Swal.fire('Success', 'Details sent the support team will contact you shortly', 'success');
            }
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }
    return (
        <div>
            <div className="page-wraper">
                <Header />
                <Loading loading={loading} />
                <div className="page-content">
                    <div className="aon-page-benner-area">
                        <div className="aon-page-banner-row" style={{ backgroundImage: 'url(images/banner/job-banner.jpg)' }}>
                            <div className="sf-overlay-main" style={{ opacity: '0.8', backgroundColor: '#022279' }} />
                            <div className="sf-banner-heading-wrap">
                                <div className="sf-banner-heading-area">
                                    <div className="sf-banner-heading-large">Contact Us</div>
                                    <div className="sf-banner-breadcrumbs-nav">
                                        <ul className="list-inline">
                                            <li><Link to={'/'}> Home </Link></li>
                                            <li>Contact us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Banner*/}
                    {/* Contact Us*/}
                    <div className="aon-contact-area">
                        <div className="container">
                            {/*Title Section Start*/}
                            <div className="section-head text-center">
                                <h2 className="sf-title">Contact Information</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div className="section-content">
                                <div className="sf-contact-info-wrap">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="sf-contact-info-box">
                                                <div className="sf-contact-icon">
                                                    <span><img src="images/contact-us/1.png" alt='' /></span>
                                                </div>
                                                <div className="sf-contact-info">
                                                    <h4 className="sf-title">Mailing Address</h4>
                                                    <p>121 King Street, Melbourne
                                                        Victoria 3000 Australia</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* COLUMNS 2 */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="sf-contact-info-box">
                                                <div className="sf-contact-icon">
                                                    <span><img src="images/contact-us/2.png" alt='' /></span>
                                                </div>
                                                <div className="sf-contact-info">
                                                    <h4 className="sf-title">Email Info</h4>
                                                    <p>info@brandcoin.com</p><p>support@brandcoin.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* COLUMNS 3 */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="sf-contact-info-box">
                                                <div className="sf-contact-icon">
                                                    <span><img src="images/contact-us/3.png" alt='' /></span>
                                                </div>
                                                <div className="sf-contact-info">
                                                    <h4 className="sf-title">Phone Number</h4>
                                                    <p>0800-123456 (24/7 Support Line)</p>
                                                    <p>0800-123654</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sf-contact-form-wrap">
                                    <div className="sf-contact-form">
                                        <div className="sf-con-form-title text-center">
                                            <h2 className="m-b30">Contact Information</h2>
                                        </div>
                                        <div className="contact-form">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" name="fullname" placeholder="Name" className="form-control" required onChange={(e) => setName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" name="email" placeholder="Email" className="form-control" required onChange={(e) => setEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" name="phone" placeholder="Phone" className="form-control" onChange={(e) => setPhone(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" name="subject" placeholder="Subject" className="form-control" required onChange={(e) => setSubject(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="message" placeholder="Message" className="form-control" required onChange={(e) => setMessage(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sf-contact-submit-btn">
                                                <button className="site-button" type="submit" onClick={handleContactus}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Contact Information End*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="section-full sf-contact-map-area">
            <div className="container">
              <div className="sf-map-social-block text-center">
                <h2>Trusted by thousands of people all over the world</h2>
                <ul className="sf-con-social">
                  <li><a href="#" className="sf-fb"><img src="images/contact-us/facebook.png" alt='' />Facebook</a></li>
                  <li><a href="#" className="sf-twitter"><img src="images/contact-us/twitter.png" alt='' />Twitter</a></li>
                  <li><a href="#" className="sf-pinterest"><img src="images/contact-us/pinterest.png" alt='' />Pinterest</a></li>
                </ul>
                <div className="sf-con-social-pic">
                  <span className="img-pos-1"><img src="images/contact-us/img1.png" alt='' /></span>
                  <span className="img-pos-2"><img src="images/contact-us/img2.png" alt='' /></span>
                  <span className="img-pos-3"><img src="images/contact-us/img3.png" alt='' /></span>
                  <span className="img-pos-4"><img src="images/contact-us/r-img1.png" alt='' /></span>
                  <span className="img-pos-5"><img src="images/contact-us/r-img2.png" alt='' /></span>
                  <span className="img-pos-6"><img src="images/contact-us/r-img3.png" alt='' /></span>
                </div>
              </div>
            </div>
            <div className="sf-map-wrap">
              <div className="gmap-area">
                <iframe src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&t=&z=14&ie=UTF8&iwloc=B&output=embed" />
              </div>
            </div>
          </div> */}
                </div>
                <Footer />
                <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
            </div>
            <LoginModal />
        </div>

    )
}

export default ContactUs