import React, { useState, useEffect } from 'react';
import VendorSidebar from '../Provider/VendorSideBar';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import handleVerify from '../config/accountvalidation';
import { useNavigate } from 'react-router-dom';

function AdminServices() {
    const [servicename, setServicename] = useState('');
    const [servicetitle, setServicetitle] = useState('');
    const [description, setDescription] = useState('');
    const [servicecost, setServicecost] = useState('');
    const [serviceimg, setServiceImg] = useState('');
    const [category, setCategory] = useState('');
    const [serviceid, setserviceId] = useState('');
    const [serviceareain, setServiceareain] = useState('');

    const router = useNavigate();

    useEffect(() => {
        handleCatDisplay();
        handleFetchService();
        handleVerify(router);
        handleServiceareafeth();
    }, []);

    const [data, setData] = useState([]);
    const [fethservice, setFethservice] = useState([]);
    const [servicearea, setServicearea] = useState([]);

    const [loading, setLoading] = useState(false);

    const handleCatDisplay = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getCatall, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
        }
    }

    const handleServiceareafeth = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.serviceAreaAll, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setServicearea(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
        }
    }

    const handleFetchService = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getServiceall, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setFethservice(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleAddservice = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('servicename', servicename);
        formData.append('servicetitle', servicetitle);
        formData.append('servicedes', description);
        formData.append('servicecost', servicecost);
        formData.append('servicecategory', category);
        formData.append('serviceimg', serviceimg);
        formData.append('servicearea', serviceareain);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.serviceadd, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Service added successfully', 'success');
                handleFetchService();
                const modal = document.getElementById('add_services');
                if (modal) {
                    modal.click();
                }
                setLoading(false);
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            if (error.response.data.error === "Cannot read properties of undefined (reading '0')") {
                Swal.fire({
                    title: 'Failed',
                    text: 'Please Upload Image',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'Failed',
                    text: error.response.data.error,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        }
    }

    const handlesetid = async (id) => {
        setserviceId(id);
    }

    const handleDelete = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deleteservice, {
                "id": serviceid,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Service deleted successfully', 'success');
                setLoading(false);
                const modal = document.getElementById('Delete_services');
                if (modal) {
                    modal.click();
                }
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleFindone = async (id) => {
        setLoading(true);
        setserviceId(id);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getServiceone + '/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setLoading(false);
            setServicename(response.data.data.servicename);
            setServicetitle(response.data.data.servicetitle);
            setDescription(response.data.data.servicedes);
            setServicecost(response.data.data.servicecost);
            setCategory(response.data.data.servicecategory);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleUpdateservice = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('servicename', servicename);
        formData.append('servicetitle', servicetitle);
        formData.append('servicedes', description);
        formData.append('servicecost', servicecost);
        formData.append('servicecategory', category);
        formData.append('serviceimg', serviceimg);
        formData.append('id', serviceid);
        formData.append('servicearea', serviceareain);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.serviceedit, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Service updated successfully', 'success');
                handleFetchService();
                handlesetdestroy();
                const modal = document.getElementById('add_services');
                if (modal) {
                    modal.click();
                }
                setLoading(false);
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            if (error.response.data.error === "Cannot read properties of undefined (reading '0')") {
                Swal.fire({
                    title: 'Failed',
                    text: 'Please Upload Image',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'Failed',
                    text: error.response.data.error,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        }
    }

    const handlesetdestroy = async () => {
        setserviceId('');
    }

    const handleSearch = async (e) => {
        const search = e.target.value;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.serviceSearch + '/' + search, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
        }
    }

    return (
        <>
            <VendorSidebar />
            <Loading loading={loading} />
            <div id="content">
                <div className="content-admin-main">
                    <div className="aon-admin-heading">
                        <h4>My Services</h4>
                    </div>

                    <div className="card aon-card">
                        <div className="card-body aon-card-body">

                            <div className="sf-bd-data-tb-head aon-mob-btn-marb">
                                <button className="admin-button m-l10" data-toggle="modal" data-target="#add_services" type="button">
                                    <i className="fa fa-plus"></i>
                                    Add a Service
                                </button>
                            </div>

                            <div className="table-responsive">
                                <div className='d-flex justify-content-end mb-3'>
                                    <div className='col-md-4'>
                                        <input type="text" className="form-control" placeholder="Search by Name" onChange={handleSearch} />
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Price</th>
                                            <th>Category</th>
                                            <th>Service Area</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fethservice && fethservice.map((item, index) => (
                                            <tr>
                                                <td><img src={lib.imgurl + '/' + item.serviceimg} alt="no img there" /></td>
                                                <td>{item.servicename}</td>
                                                <td>{item.servicetitle}</td>
                                                <td>{item.servicedes}</td>
                                                <td>{item.servicecost}</td>
                                                <td>{item.servicecategory}</td>
                                                <td>{item.servicearea}</td>
                                                <td>
                                                    <button className='btn btn-danger' data-toggle="modal" data-target="#Delete_services" onClick={() => handlesetid(item.id)}>Delete</button>
                                                    <button className="admin-button m-l10" data-toggle="modal" data-target="#add_services" type="button" onClick={() => handleFindone(item.id)}>
                                                        <i className="fa fa-plus"></i>
                                                        Edit Service
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div >


                    {/* <!--Modal add services-- > */}
                    <div className="modal fade content-admin-main" id="add_services" tabindex="-1" role="dialog" ariaHidden="true" >
                        <div className="modal-dialog model-w800" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {serviceid ? (
                                        <h5 className="modal-title" id="exampleModalLabel2">Update Services</h5>
                                    ) : (
                                        <h5 className="modal-title" id="exampleModalLabel2">Add New Services</h5>
                                    )}
                                    <button type="button" className="close" data-dismiss="modal" ariaLabel="Close">
                                        <span ariaHidden="true" onClick={handlesetdestroy}>&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="sf-md-padding">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Services Image</label>
                                                    <div className="aon-inputicon-box">
                                                        <input type="file" name="avatar" accept="image/*" className='form-control sf-form-control' onChange={(e) => setServiceImg(e.target.files[0])} />
                                                        <i className="aon-input-icon fa fa-camera"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Services Name</label>
                                                    <div className="aon-inputicon-box">
                                                        <input className="form-control sf-form-control" type="text" value={servicename} onChange={(e) => setServicename(e.target.value)} />
                                                        <i className="aon-input-icon fa fa-user"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Services Title</label>
                                                    <div className="aon-inputicon-box">
                                                        <input className="form-control sf-form-control" type="text" value={servicetitle} onChange={(e) => setServicetitle(e.target.value)} />
                                                        <i className="aon-input-icon fa fa-info-circle"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label >Services Description</label>
                                                    <div className="aon-inputicon-box">
                                                        <input className="form-control sf-form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                                                        <i className="aon-input-icon fa fa-comment"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Services Cost</label>
                                                    <div className="aon-inputicon-box">
                                                        <input className="form-control sf-form-control" name="company_name" type="text" value={servicecost} onChange={(e) => setServicecost(e.target.value)} />
                                                        <i className="aon-input-icon fa fa-dollar"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Services Category</label>
                                                    <div className="sf-search-feild">
                                                        <select className="form-control" title="Category" value={category} onChange={(e) => setCategory(e.target.value)}>
                                                            <option className="bs-title-option" value="">Select a Category</option>
                                                            {data && data.map((item, index) => (
                                                                <option value={item.categoryname}>{item.categoryname}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Services Area</label>
                                                    <div className="sf-search-feild">
                                                        <select className="form-control" title="Category" value={serviceareain} onChange={(e) => setServiceareain(e.target.value)}>
                                                            <option className="bs-title-option" value="">Select Service Area</option>
                                                            {servicearea && servicearea.map((item, index) => (
                                                                <option value={item.servicearea}>{item.servicearea}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {serviceid ? (
                                    <div className="modal-footer">
                                        <button type="button" className="admin-button" data-dismiss="modal" onClick={handlesetdestroy}>Cancel</button>
                                        <button type="button" className="admin-button" onClick={handleUpdateservice}>Update</button>
                                    </div>
                                ) : (
                                    <div className="modal-footer">
                                        <button type="button" className="admin-button" data-dismiss="modal" onClick={handlesetdestroy}>Cancel</button>
                                        <button type="button" className="admin-button" onClick={handleAddservice}>Create</button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    {/* <>Delete Modle Starts hear</> */}
                    <div className="modal fade content-admin-main" id="Delete_services" tabindex="-1" role="dialog" ariaHidden="true" >
                        <div className="modal-dialog model-w800" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel2">Delete Modle</h5>
                                    <button type="button" className="close" data-dismiss="modal" ariaLabel="Close">
                                        <span ariaHidden="true" onClick={handlesetdestroy}>&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="sf-md-padding">
                                        <div className="row">
                                            <h5 className="modal-title ml-100" id="exampleModalLabel2">Are you sure you want to delete it...</h5>

                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="admin-button" data-dismiss="modal">No</button>
                                    <button type="button" className="admin-button" onClick={handleDelete}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}

export default AdminServices;