import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';

const ProviderSignUp = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [doc1, setDoc1] = useState('');
    const [doc2, setDoc2] = useState('');

    const [loading, setLoading] = useState(false);

    const handleProviderSignup = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('status', 'pending');
            formData.append('pass', password);
            formData.append('doc1', doc1);
            formData.append('doc2', doc2);

            const response = await axios.post(lib.apiURL + '/' + lib.apis.providersignup, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Provider Login Successfully', 'success');
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            Swal.fire({
                title: 'Failed',
                text: err.response.data.msg,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }
    return (
        <div>
            <Header />
            <div className='pt-5'>
                <div className='pt-lg-5'>
                    <div className="aon-contact-area p-0">
                        <div className="container">
                            <div className="section-content">
                                <div className="sf-contact-form-wrap">
                                    <div className="sf-contact-form">
                                        <div className="sf-con-form-title text-center">
                                            <h2 className="m-b30">Register yourself as a provider with us..!</h2>
                                        </div>
                                        <div className="row">
                                            {/* Name field*/}
                                            <div className='col-md-12 d-flex justify-content-center'>
                                                <div className='col-md-6'>

                                                    <Loading loading={loading}/>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="fullname"
                                                                placeholder="Name"
                                                                className="form-control"
                                                                required
                                                                onChange={(e) => setName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email"
                                                                className="form-control"
                                                                required
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                name="phone"
                                                                placeholder="Phone Number"
                                                                className="form-control"
                                                                onChange={(e) => setPhone(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="password"
                                                                name="phone"
                                                                placeholder="Password"
                                                                className="form-control"
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* kyc documents */}
                                                    <div className="col-md-12">
                                                        <span>Upload document for kyc</span>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="file"
                                                                name="kycDocument1"
                                                                className="form-control"
                                                                onChange={(e) => setDoc1(e.target.files[0])}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="file"
                                                                name="kycDocument2"
                                                                className="form-control"
                                                                onChange={(e) => setDoc2(e.target.files[0])}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sf-contact-submit-btn">
                                            <button className="site-button" type="button" onClick={handleProviderSignup}>Submit</button>
                                            <div>
                                                <Link to="/ProviderLogin">Already Registered ? login with Provider</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--Contact Information End--> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProviderSignUp;