import React, { useEffect, useState } from 'react'
import Loading from './Loading'
import Header from './Header'
import LoginModal from './LoginModal'
import Footer from './Footer'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import lib from '../config/lib'

const CategoriesDetail = () => {
    const [loading, setLoading] = useState(false);
    const [service, setService] = useState([]);

    const [prise, setPrise] = useState('');
    const [servicerarea, setServicerarea] = useState('');

    const [serviceareadisplay, setServiceareadisplay] = useState([]);

    const location = useLocation();
    const { catsiaplay } = location.state;

    useEffect(() => {
        handleservicefeth();
        handleCompleteby();
    }, []);

    const handleservicefeth = async () => {
        setLoading(true);
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getServicehome + '/' + catsiaplay);
            // console.log(response.data.data);
            setService(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleBylocation = async (e) => {
        setLoading(true);
        const servicerareain = e.target.value;
        setServicerarea(e.target.value);
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getserviceBylocation + '?servicecategory=' + catsiaplay + '&servicearea=' + servicerareain);
            // console.log(response.data.data);
            setService(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleCompleteby = async () => {
        setLoading(true);
        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.serviceAreaAllhome);
            // console.log('serviceimg', response.data.data);
            setServiceareadisplay(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    /// want to start from hear

    return (
        <div>
            <div className="page-wraper">
                <Header />
                <div className="page-content">
                    <div className="section-content sf-allCaty-grid-wrap sf-owl-arrow pt-5 pb-1">
                        <div className="container">
                            <div className="section-head">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2 className="sf-title text-white">{catsiaplay.charAt(0).toUpperCase() + catsiaplay.slice(1).toLowerCase()}</h2>
                                    </div>
                                    <div className="col-md-6">
                                    </div>
                                </div>
                            </div>
                            {/*Title Section End*/}
                        </div>
                    </div>
                    <div className="section-content sf-caty-listResult-wrap">
                        <div className="container">
                            <div className="section-content">
                                <div className="sf-search-result-top flex-wrap d-flex justify-content-between mb-5">
                                    <div class=" boreder-0 col-md-4">
                                        <h4 class="sf-title">Select your service area</h4>
                                        <div class="category-select">
                                            <select class="form-control sf-select-box" title="All area" onChange={handleBylocation}>
                                                <option> Select Service area </option>
                                                {serviceareadisplay && serviceareadisplay.map((item, index) => (
                                                    <option value={item.servicearea}>{item.servicearea}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div class=" boreder-0 col-md-4">
                                        <h4 class="sf-title">Filter by price</h4>
                                        <div class="category-select">
                                            <select class="form-control sf-select-box" title="All price" onChange={(e) => setPrise(e.target.value)}>
                                                <option> Select a price </option>
                                                <option>Low</option>
                                                <option>high</option>
                                                <option>Low to high</option>
                                                <option>High to low</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    {service && service.map((item, index) => (
                                        <div className="col-md-6">
                                            <Link className="sf-vender-pic-link" to='/jobdetails' state={{ serviceid: item.id, serviceimg: item.serviceimg, providerid: item.createdby, sername: item.servicename, serdes: item.servicedes }} >
                                                <div className="sf-vender-list-wrap">
                                                    <div className="sf-vender-list-box d-flex">
                                                        <div className="sf-vender-list-pic" style={{ backgroundImage: `url(${lib.imgurl}/${item.serviceimg})` }}>
                                                            <Link className="sf-vender-pic-link" to='/jobdetails' state={{ serviceid: item.id, serviceimg: item.serviceimg, providerid: item.createdby, sername: item.servicename, serdes: item.servicedes }} />
                                                        </div>
                                                        <div className="sf-vender-list-info">
                                                            <h4 className="sf-venders-title"><Link to='/jobdetails' state={{ serviceid: item.id, serviceimg: item.serviceimg, providerid: item.createdby, sername: item.servicename, serdes: item.servicedes }}>{item.servicetitle}</Link></h4>
                                                            <span className="sf-venders-address">{item.servicename}</span>
                                                            <br />
                                                            <span className="sf-venders-address"><i className="fa fa-map-marker" />{item.servicearea}</span>
                                                            <br />
                                                            <br />
                                                            <p>{item.servicedes}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}

                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
            </div>
            <LoginModal />
        </div>

    )
}

export default CategoriesDetail