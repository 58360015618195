import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import LoginModal from './LoginModal';
import Loading from './Loading';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';

const CutomerBookings = () => {
    const [loading, setLoading] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        handleFethBooking();
    }, []);

    const handleFethBooking = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getBookingbycustomer, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <>
            <div>
                <div className="page-wraper">
                    <Header />
                    <Loading loading={loading} />
                    <div className="page-content">
                        <div className="section-content sf-allCaty-grid-wrap sf-owl-arrow pt-5 pb-1">
                            <div className="container">
                                {/*Title Section Start*/}
                                <div className="section-head">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2 className="sf-title text-white">My Booking</h2>
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                </div>
                                {/*Title Section End*/}
                            </div>
                        </div>
                        <div className="section-content sf-caty-listResult-wrap">
                            <div className="container">
                                <div className="section-content">
                                    <div className="content-admin-main">
                                        <div className="card aon-card">
                                            <div className="card-body aon-card-body">
                                                {/* <!-- Week Tabs--> */}
                                                <div className="sf-availability-times-tab m-b50">
                                                    <div className="sf-custom-tabs sf-custom-new">
                                                        {/* <!--Tabs Content--> */}
                                                        <div className="tab-content">

                                                            {/* <!--Upcoming--> */}
                                                            <div id="Upcoming" className="tab-pane active">
                                                                <div className="sf-tabs-content">
                                                                    <div className="sf-bs-data-table">
                                                                        <div className="table-responsive">

                                                                            {data && data.map((item, index) => (
                                                                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Booking Info</th>
                                                                                            <th>Payment Info</th>
                                                                                            <th>Status</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <div className="sf-booking-info-col">
                                                                                                        <span className="sf-booking-refid">{item.id}</span>
                                                                                                        <span className="booking-status sf-booking-incomplete">{item.status}</span>
                                                                                                        <div className="sf-booking-upcoming">
                                                                                                            {item.quationstatus}
                                                                                                        </div>
                                                                                                        <div className="sf-booking-customer">
                                                                                                            <ul className="customer-info">
                                                                                                                <li><strong><i className="fa fa-user"></i> Provider Name</strong>{item.Provider.name}</li>
                                                                                                                <li><strong><i className="fa fa-user"></i> Provider Email</strong>{item.Provider.email}</li>
                                                                                                                <li><strong><i className="fa fa-phone"></i> Provider Phone</strong>{item.Provider.phone}</li>
                                                                                                                <li><strong><i className="fa fa-user"></i> Provider Address</strong>{item.Provider.address}</li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div className="inner">
                                                                                                        <h3>
                                                                                                            {item.payment === "pending" ? (
                                                                                                                <span className="sf-booking-payment-info" data-toggle="popover" data-container="body" data-placement="top" data-html="true" id="payinfo-1115" data-trigger="hover" data-original-title="" title="">$0</span>
                                                                                                            ) : (
                                                                                                                <span className="sf-booking-payment-info" data-toggle="popover" data-container="body" data-placement="top" data-html="true" id="payinfo-1115" data-trigger="hover" data-original-title="" title="">${item.payment}</span>
                                                                                                            )}
                                                                                                            <span className="sf-payment-status">{item.paymentstatus}</span>
                                                                                                        </h3>
                                                                                                        <div id="popover-content-payinfo-1115" className="hide sf-pop-hide">
                                                                                                            <ul className="list-unstyled margin-0 booking-payment-data">
                                                                                                                <li><strong>Total Amount:</strong> 85.00$</li>
                                                                                                                <li><strong>Providers Fee:</strong> 57.00$</li>
                                                                                                                <li><strong>Admin Fee:</strong> 28.00$</li>
                                                                                                                <li><strong>Payment Method:</strong> </li>
                                                                                                                <li><strong>Admin pay to providers:</strong> Pending</li>
                                                                                                                <li><strong>Tranaction ID:</strong> NA</li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {item.status}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    </tbody>
                                                                                </table>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Pagination Start*/}
                                    {/* <div className="site-pagination s-p-center">
                                        <ul className="pagination">
                                            <li className="page-item disabled">
                                                <a className="page-link" href="#" tabIndex={-1}><i className="fa fa-chevron-left" /></a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item"><a className="page-link" href="#"><i className="fa fa-ellipsis-h" /></a></li>
                                            <li className="page-item"><a className="page-link" href="#">11</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#"><i className="fa fa-chevron-right" /></a>
                                            </li>
                                        </ul>
                                    </div> */}
                                    {/*Pagination End*/}

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
                </div>
                {/* Login Sign Up Modal */}
                <LoginModal />
            </div>
        </>
    );
}

export default CutomerBookings;