import React from 'react'
import Loading from './Loading'
import Header from './Header'
import LoginModal from './LoginModal'
import Footer from './Footer'
import { Link } from 'react-router-dom'

const MyCart = () => {
  return (
    <div>
      {/* LOADING AREA START ===== */}
      {/* <Loading/> */}
      {/* LOADING AREA  END ====== */}
      <div className="page-wraper">        {/* HEADER START */}
        <Header />
        {/* HEADER END */}
        {/* Content */}
        <div className="page-content">
          <div className="section-content sf-allCaty-grid-wrap sf-owl-arrow pt-5 pb-1">
            <div className="container">
              {/*Title Section Start*/}
              <div className="section-head">
                <div className="row">
                  <div className="col-md-6">
                    <h2 className="sf-title text-white">My Cart</h2>
                  </div>
                  <div className="col-md-6">
                  </div>
                </div>
              </div>
              {/*Title Section End*/}
            </div>
          </div>
          <div className="section-content sf-caty-listResult-wrap">
            <div className="container">
              <div className="section-content">
                {/*Showing results topbar Start*/}
                <div className="sf-search-result-top flex-wrap d-flex justify-content-between">
                  <div className="sf-search-result-title"> <h5>Showing 1 – 10 of 16 results</h5></div>
                  <div className="sf-search-result-option">
                    <ul className="sf-search-sortby">
                      <li className="sf-select-sort-by">
                        <select className="sf-select-box form-control sf-form-control bs-select-hidden" title="SORT BY" name="setorderby" id="setorderby">
                          <option className="bs-title-option" value>SORT BY</option>
                          <option value="rating">Rating</option>
                          <option value="title">Title</option>
                          <option value="distance">Distance</option>
                        </select>
                      </li>
                      <li>
                        <select className="sf-select-box form-control sf-form-control bs-select-hidden" title="DESC" name="setorder" id="setorder">
                          <option className="bs-title-option" value>DESC</option>
                          <option value="asc">ASC</option>
                          <option value="desc">DESC</option>
                        </select>
                      </li>
                      <li>
                        <select className="sf-select-box form-control sf-form-control bs-select-hidden" title={9} name="numberofpages" id="numberofpages">
                          <option className="bs-title-option" value>9</option>
                          <option value={9}>9</option>
                          <option value={12}>12</option>
                          <option value={15}>15</option>
                          <option value={20}>20</option>
                          <option value={25}>25</option>
                          <option value={30}>30</option>
                        </select>
                      </li>
                    </ul>
                    <ul className="sf-search-grid-option" id="viewTypes">
                      <li data-view="grid-3">
                        <button type="button" className="btn btn-border btn-icon"><i className="fa fa-th" /></button>
                      </li>
                      <li data-view="listview" className="active">
                        <button type="button" className="btn btn-border btn-icon"><i className="fa fa-th-list" /></button>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Showing results topbar End*/}
                <div className="row">
                  {/*BLock 1*/}
                  <div className="col-md-6">
                  <Link className="sf-vender-pic-link" to={'/ProfileFull'} >
                    <div className="sf-vender-list-wrap">
                      <div className="sf-vender-list-box d-flex">
                        <div className="sf-vender-list-pic" style={{ backgroundImage: 'url(images/categories/pic1.jpg)' }}>
                          <Link className="sf-vender-pic-link" to={'/ProfileFull'} />
                        </div>
                        <div className="sf-vender-list-info">
                          <h4 className="sf-venders-title"><Link to={'/ProfileFull'}>Colin Farrell</Link></h4>
                          <span className="sf-venders-address"><i className="fa fa-map-marker" />Queens, United States</span>
                          <div className="sf-ow-pro-rating">
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star text-gray" />
                          </div>
                          <p>Through our expertise, technological knowledge, global presence and bespoke.</p>
                          <div className="sf-pro-check"><span><i className="fa fa-check" /></span></div>
                          <div className="sf-pro-favorite"><a href="#"><i className="fa fa-heart-o" /></a></div>
                          <div className="dropdown action-dropdown dropdown-left">
                            <button className="action-button gray dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-ellipsis-v" /></button>
                            <ul className="dropdown-menu">
                              <li><a href="javascript:;"><i className="feather-sliders" /> Display Services</a></li>
                              <li><a href="javascript:;"><i className="feather-save" /> 0 Review Comments</a></li>
                              <li><a href="javascript:;"><i className="feather-trash" /> Request A Quote</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                  {/*BLock 2*/}
                  <div className="col-md-6">
                  <Link className="sf-vender-pic-link" to={'/ProfileFull'} >
                    <div className="sf-vender-list-wrap">
                      <div className="sf-vender-list-box d-flex">
                        <div className="sf-vender-list-pic" style={{ backgroundImage: 'url(images/categories/pic2.jpg)' }}>
                          <Link className="sf-vender-pic-link" to={'/ProfileFull'} />
                        </div>
                        <div className="sf-vender-list-info">
                          <h4 className="sf-venders-title"><Link to={'/ProfileFull'}>Edward Luise</Link></h4>
                          <span className="sf-venders-address"><i className="fa fa-map-marker" />Queens, United States</span>
                          <div className="sf-ow-pro-rating">
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star text-gray" />
                          </div>
                          <p>Through our expertise, technological knowledge, global presence and bespoke.</p>
                          <div className="sf-pro-check"><span><i className="fa fa-check" /></span></div>
                          <div className="sf-pro-favorite"><a href="#"><i className="fa fa-heart-o" /></a></div>
                          <div className="dropdown action-dropdown dropdown-left">
                            <button className="action-button gray dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="true"><i className="fa fa-ellipsis-v" /></button>
                            <ul className="dropdown-menu">
                              <li><a href="javascript:;"><i className="feather-sliders" /> Display Services</a></li>
                              <li><a href="javascript:;"><i className="feather-save" /> 0 Review Comments</a></li>
                              <li><a href="javascript:;"><i className="feather-trash" /> Request A Quote</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>    
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Content END*/}
        {/* FOOTER START */}
        <Footer />
        {/* FOOTER END */}
        {/* BUTTON TOP START */}
        <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
      </div>
      {/* Login Sign Up Modal */}
      <LoginModal />
    </div>
  )
}

export default MyCart