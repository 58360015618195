import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';

const Header = () => {
    const [show, setShow] = useState('');
    const [loading, setLoading] = useState('');
    const [isButtonActive, setButtonActive] = useState(false);

    const router = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            setShow(true);
        }
        else {
            setShow(false);
        }
    }, []);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    const handleLogout = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.logout, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if(response.data.status === "success"){
                localStorage.removeItem('token');
                Swal.fire('success', 'You are logout', 'success');
                window.location.reload();
                setLoading(false);
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div>
            <header className={`site-header header-style-2 mobile-sider-drawer-menu header-full-width ${isButtonActive ? 'active' : ''}`}>
                {/* <div className="sticky-header main-bar-wraper navbar-expand-lg"> */}
                <div className='mb-5'>
                    <div className="sticky-header main-bar-wraper navbar-expand-lg is-fixed pb-5">
                        <div className="main-bar">
                            <div className="container clearfix">
                                {/*Logo section start*/}
                                <div className="logo-header">
                                    <div className="logo-header-inner logo-header-one">
                                        <Link to={'/'}>
                                            <img src="images/logo.png" alt='' />
                                        </Link>
                                    </div>
                                </div>
                                <button
                                    id="mobile-side-drawer"
                                    data-target=".header-nav"
                                    data-toggle="collapse"
                                    type="button"
                                    className={`navbar-toggler collapsed ${isButtonActive ? 'active' : ''}`}
                                    onClick={handleManu}
                                >
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar icon-bar-first" />
                                    <span className="icon-bar icon-bar-two" />
                                    <span className="icon-bar icon-bar-three" />
                                </button>
                                {/* MAIN Vav */}
                                <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-start">
                                    <ul className=" nav navbar-nav">
                                        <li className="has-child current-menu-item"><Link to={'/'}>Home</Link></li>
                                        <li><Link to='/AllCategoryDetaile'>Category</Link></li>
                                        <li><Link to='/ContactUs'>Contact</Link></li>
                                        {show === true ? (
                                            <li className="has-child"><a href='#'>Profile</a>
                                                <ul className="sub-menu">
                                                    <li><Link to='/UserProfile'>My Profile</Link></li>
                                                    <li><a onClick={handleLogout}>LogOut</a></li>
                                                </ul>
                                            </li>
                                        ) : (
                                            <></>
                                        )}

                                    </ul>
                                </div>
                                {/* Header Right Section*/}
                                <div className="extra-nav header-2-nav">
                                    <div className="extra-cell">

                                        {show === true ? (
                                            <>
                                                {/* <Link to="/mycart" className="site-button aon-btn-signup m-l20">
                                                <i className="fa fa-shopping-cart" /> Cart
                                            </Link> */}
                                                <Link to="/mybooking" className="site-button aon-btn-signup m-l20">
                                                    <i className="fa fa-calendar" /> My booking
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                <button type="button" className="site-button aon-btn-login" data-toggle="modal" data-target="#login-signup-model">
                                                    <i className="fa fa-user" /> Login
                                                </button>
                                                <Link to="/ProviderLogin" className="site-button aon-btn-signup m-l20">
                                                    <img src='https://cdn-icons-png.flaticon.com/512/4862/4862383.png' className='provider-icon' /> Provider
                                                </Link>
                                            </>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header