import React from 'react'
import HomePage from './components/Home'

const Main = () => {
  return (
    <div>
      <HomePage/>
    </div>
  )
}

export default Main;