import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";
import lib from "../config/lib";
import axios from "axios";
import Swal from "sweetalert2";
import LoginModal from "./LoginModal";
import Modal from 'react-bootstrap/Modal';
import Loading from "./Loading";

const JobDetails = () => {
    const [loading, setLoading] = useState(false);
    const [profileImg, setProfileImg] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [createdat, setCreatedat] = useState('');

    const [serviceimg, setServiceImg] = useState('');
    const [sername, setServiceName] = useState('');
    const [servicetitle, setServicetitle] = useState('');
    const [serdes, setDes] = useState('');
    const [servicecost, setServicecost] = useState('');
    const [servicearea, setServicearea] = useState('');
    const [category, setCategory] = useState('');
    const [servicecreatedat, setServicecreatedat] = useState('');

    const [addressupdate, setAddressupdate] = useState('');

    const location = useLocation();
    const { serviceid, providerid } = location.state;

    const router = useNavigate();

    useEffect(() => {
        handlefetchprovider();
        getServiceoneHome();
        handleChecklogin();
    }, []);

    const handlefetchprovider = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getProviderbyid + '/' + providerid, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setProfileImg(response.data.data.profileImg);
            setName(response.data.data.name);
            setAddress(response.data.data.address);
            setCity(response.data.data.city);
            setState(response.data.data.state);

            const createdAtString = response.data.data.createdAt;
            const createdAtDate = new Date(createdAtString);
            const year = createdAtDate.getFullYear();
            const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
            const day = String(createdAtDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;

            setCreatedat(formattedDate);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const getServiceoneHome = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getServiceoneHome + '/' + serviceid, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = response.data.data;
            setServiceImg(data.serviceimg);
            setServiceName(data.servicename);
            setServicetitle(data.servicetitle);
            setDes(data.servicedes);
            setServicecost(data.servicecost);
            setServicearea(data.servicearea);
            setCategory(data.servicecategory);

            const createdAtString = data.createdAt;
            const createdAtDate = new Date(createdAtString);
            const year = createdAtDate.getFullYear();
            const month = String(createdAtDate.getMonth() + 1).padStart(2, '0');
            const day = String(createdAtDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;

            setServicecreatedat(formattedDate);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleUpdateaddress = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.addressupdateoncheckout, {
                "address": addressupdate,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (response.data.status === "success") {
                Swal.fire('success', 'Address updated.', 'success')
            }
        }
        catch (error) {
            Swal.fire('error', 'Server down please try again after some time', 'error');
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleChecklogin = () => {
        const token = localStorage.getItem('token');

        if (!token) {
            setIsModalOpen(true);
        }
        else {
            setIsModalOpen(false);
        }
    }

    const handleBooking = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.handlebooking, {
                "providerid": providerid,
                "serviceid": serviceid
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if(response.data.status === "success"){
                Swal.fire('success', 'Booking done successfully.', 'success');
                router('/mybooking');
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <>
            <div className="page-wraper">
                <Header />
                <Loading loading={loading}/>
                <div className="page-content bg-white">
                    <div className="sf-job-benner sf-overlay-wrapper">
                        <div className="banner-job-row">
                            <div className="sf-overlay-main" style={{ opacity: 0 }} />
                            <div className="sf-banner-job-heading-wrap">
                                <div className="sf-banner-job-heading-area">
                                    <div className="sf-banner-job-logo-pic"><img src={lib.imgurl + '/' + serviceimg} alt /></div>
                                    <div className="sf-banner-job-heading-large">{sername}</div>
                                    <ul className="sf-banner-job-dutation">
                                        <li><i className="fa fa-clock-o" /> Provider: <span className="jobs-timing">{name}</span></li>
                                        <li><i className="fa fa-hourglass-o" /> Provider Registered On: <span className="jobs-date-label">{createdat}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">


                        <div className="container">
                            <div className="sf-job-details-fileds sf-job-details-fileds-two">
                                <ul className="job-listing-meta meta">
                                    <li className="location d-flex justify-content-between">
                                        <div>
                                            <span className="job-meta-icon"><img src="images/job-cin/location.png" alt /></span>
                                            <div className="sf-job-meta-info">
                                                <h5 className="job-meta-title">Provider Location:</h5>
                                                <span className="job-meta-text">{city}, {state}</span>
                                            </div>
                                        </div>
                                        {/* <button type="button" className="site-button aon-btn-login" data-toggle="modal" data-target="#add-address">
                                            + Address
                                        </button> */}
                                    </li>
                                    <li className="d-flex justify-content-between">
                                        <div>
                                            <span className="job-meta-icon"><img src="images/job-cin/money.png" alt /></span>
                                            <div className="sf-job-meta-info">
                                                <h5 className="job-meta-title">Approx Price:</h5>
                                                <span className="job-meta-text text-red">${servicecost}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="job-type hourly">
                                        <span className="job-meta-icon"><img src="images/job-cin/brifcase.png" alt /></span>
                                        <div className="sf-job-meta-info">
                                            <h5 className="job-meta-title">Details about service:</h5>
                                            <span className="job-meta-text">{serdes}</span>
                                        </div>
                                    </li>
                                    <li className="location">
                                        <span className="job-meta-icon"><img src="images/job-cin/tag.png" alt /></span>
                                        <div className="sf-job-meta-info">
                                            <h5 className="job-meta-title">Categories:</h5>
                                            <span className="job-meta-text">{category}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="job-meta-icon"><img src="images/job-cin/clock.png" alt /></span>
                                        <div className="sf-job-meta-info">
                                            <h5 className="job-meta-title">Estimated time:</h5>
                                            <span className="job-meta-text">50 min</span>
                                        </div>
                                    </li>
                                    <li className="date-posted">
                                        <span className="job-meta-icon"><img src="images/job-cin/user.png" alt /></span>
                                        <div className="sf-job-meta-info">
                                            <h5 className="job-meta-title">Posted On:</h5>
                                            <span className="job-meta-text">{servicecreatedat}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <p style={{ color: 'red' }}>
                                        The mobile number of the provider will be displayed after booking quation.
                                    </p>
                                    <br />
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <aside className="sf-jobdetail-sidebar">
                                        <div className="sf-jobdetail-blocks">
                                            {isModalOpen && isModalOpen ? (
                                                <button type="button" className="sf-btn-large2" data-toggle="modal" data-target="#login-signup-model">
                                                     Book Quation <i className="fa fa-send" />
                                                </button>
                                            ) : (
                                                <button className="sf-btn-large2" onClick={handleBooking}>Book Quation <i className="fa fa-send" /></button>
                                            )}

                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* Address Modal */}
                            <div className="modal fade" id="add-address">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <button type="button" className="close aon-login-close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="modal-body">
                                            <div className="sf-custom-tabs sf-custom-new aon-logon-sign-area p-3">
                                                <ul className="nav nav-tabs nav-table-cell">
                                                    <li><a data-toggle="tab" href="#Upcoming" className="active">Add Address</a></li>
                                                </ul>
                                                <div className="tab-content">
                                                    {/*Address Form*/}
                                                    <div id="Upcoming" className="tab-pane active">
                                                        <div className="sf-tabs-content">
                                                            <div className="aon-login-form">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <input className="form-control sf-form-control" name="address" type="text" placeholder="Enter address line1 street, landmark, colony, house no." onChange={(e) => setAddressupdate(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <button type="submit" className="site-button w-100" onClick={handleUpdateaddress}>Submit <i className="feather-arrow-right" /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Payment summary Modal */}
                            <div className="modal fade" id="payment-summary">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <button type="button" className="close aon-login-close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="modal-body">
                                            <div className="sf-custom-tabs sf-custom-new aon-logon-sign-area p-3">
                                                <ul className="nav nav-tabs nav-table-cell">
                                                    <li><a data-toggle="tab" href="#Upcoming" className="active">View summary</a></li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div id="Upcoming" className="tab-pane active">
                                                        <div className="col-md-12">
                                                            <aside className="side-bar ">

                                                                {/* <div className="widget rounded-sidebar-widget">
                                                                    <div className="text-left m-b30">
                                                                        <h3 className="widget-title">Service</h3>
                                                                    </div>
                                                                    <div className="widget_social_inks">
                                                                        <li>Cart</li>
                                                                    </div>
                                                                </div>

                                                           
                                                                <div className="widget rounded-sidebar-widget">
                                                                    <div className="widget_search_bx">
                                                                        <div className="text-left m-b30">
                                                                            <h3 className="widget-title">Coupon and offres</h3>
                                                                        </div>
                                                                        <li>Save 50% off on this order</li>
                                                                    </div>
                                                                </div> */}

                                                                <div className="widget widget_services rounded-sidebar-widget">
                                                                    <div className="text-left m-b30">
                                                                        <h3 className="widget-title">Payment summary</h3>
                                                                    </div>
                                                                    <ul>
                                                                        <li><span>Item total</span><span className="badge">${servicecost}.00</span></li>
                                                                        {/* <li><span>Item discount</span><span className="badge">R. 05.00</span></li> */}
                                                                        <li><span>Taxes and Fee</span><span className="badge">${servicecost * 18 / 100}.00</span></li>
                                                                        <hr />
                                                                        <li><h4 className='d-flex justify-content-between'><span>Total</span><span>${parseFloat(servicecost) + parseFloat(servicecost * 18 / 100)}.00</span></h4></li>
                                                                    </ul>
                                                                </div>
                                                                {/* <div className='d-flex justify-content-center'><button className="site-button">Pay R 57.00</button></div> */}
                                                            </aside>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginModal />
                        </div>
                    </div>
                    <Footer />
                    <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
                </div>
            </div>
        </>
    )
};

export default JobDetails;