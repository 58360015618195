import React, { useEffect, useState } from 'react'
import LoginModal from './LoginModal'
import Loading from './Loading'
import Header from './Header'
import Footer from './Footer'
import { Link, useSearchParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import lib from '../config/lib'
import axios from 'axios';
import Swal from 'sweetalert2';

const ProfileFull = () => {
    const [loading, setLoading] = useState('');
    const [data, setData] = useState('');

    const location = useLocation();
    const { providerid } = location.state || {};

    const [profileImg, setProfileImg] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        handlefetchprovider();
        generateRandomNumber();
        handleProviderservice();
    }, []);

    const handlefetchprovider = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getProviderbyid + '/' + providerid, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setProfileImg(response.data.data.profileImg);
            setName(response.data.data.name);
            setAddress(response.data.data.address);
            setCity(response.data.data.city);
            setState(response.data.data.state);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleProviderservice = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getServicebyprovider + '/' + providerid, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setData(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const [randomNumber, setRandomNumber] = useState('');

    const generateRandomNumber = () => {
        const newRandomNumber = Math.floor(Math.random() * 10) + 1; // Generates a random number between 1 and 10
        setRandomNumber(newRandomNumber);
    };



    return (
        <div>
            <div className="page-wraper">
                <Header />
                <Loading loading={loading} />
                <div className="page-content">

                    <div className="sf-page-scroll-wrap sf-page-scroll-wrap2">
                        <div className="container">
                            {/* <div className="sf-page-scroll-nav clearfix">
                                <ul className="clearfix">
                                    <li><a href="#aon-provider-info">About</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div className="container">
                        <div className="sf-provi-bio-box cleafix margin-b-50 sf-provi-fullBox row align-items-center">
                            <div className="col-md-3">
                                <div className="sf-provi-bio-info">
                                    <div className="sf-provi-pic"><img src={lib.imgurl + '/' + profileImg} alt='' /></div>
                                </div>
                            </div>
                            <div className="sf-provi-bio-right col-md-9 ">
                                <h3 className="sf-provi-title">{name}</h3>
                                <div className="sf-provi-bio-text">
                                    <div className="sf-provi-cat"><strong>Greetings! I am {name} from {city} {state}, a passionate and dedicated to Services, with a commitment to delivering top-notch services. With {randomNumber} years of experience in the industry, I take pride in my ability to provide service as given blow.</strong></div>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-height mb-4">
                        {data && data.map((item, index) => (
                            <div className="col-md-6">
                                <Link className="sf-vender-pic-link" to='/jobdetails' state={{ serviceid: item.id, serviceimg: item.serviceimg, providerid: item.createdby, sername: item.servicename, serdes: item.servicedes }} >
                                    <div className="sf-vender-list-wrap">
                                        <div className="sf-vender-list-box d-flex">
                                            <div className="sf-vender-list-pic" style={{ backgroundImage: `url(${lib.imgurl}/${item.serviceimg})` }}>
                                                <Link className="sf-vender-pic-link" to='/jobdetails' state={{ serviceid: item.id, serviceimg: item.serviceimg, providerid: item.createdby, sername: item.servicename, serdes: item.servicedes }} />
                                            </div>
                                            <div className="sf-vender-list-info">
                                                <h4 className="sf-venders-title"><Link to='/jobdetails' state={{ serviceid: item.id, serviceimg: item.serviceimg, providerid: item.createdby, sername: item.servicename, serdes: item.servicedes }}>{item.servicetitle}</Link></h4>
                                                <span className="sf-venders-address">{item.servicename}</span>
                                                <br />
                                                <span className="sf-venders-address"><i className="fa fa-map-marker" />{item.servicearea}</span>
                                                <br />
                                                <br />
                                                <p>{item.servicedes}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}

                    </div>
                    </div>
                </div>
                <Footer />
                <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
            </div>
            {/* Login Sign Up Modal */}
            <LoginModal />
        </div>

    )
}

export default ProfileFull