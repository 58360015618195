
import { Link } from 'react-router-dom';

function ProvierLogin() {

    return (
        <>
            {/* <!--Login Form--> */}
            <div className='p-5'>
                <div className='pt-lg-5'>
                    <div className='col-md-12 d-flex justify-content-center'>
                        <div className='col-md-4 col-lg-3'>
                            <div className='col-md-12 text-center mb-4'><h3>Create Password</h3></div>
                            <div id="Upcoming" className="tab-pane active">
                                <div className="sf-tabs-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input className="form-control sf-form-control" name="company_name"
                                                    type="Password" placeholder="Password" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Confirm Password</label>
                                                <input className="form-control sf-form-control" name="company_name"
                                                    type="password" placeholder="Confirm password" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit" className="site-button w-100">Submit <i className="feather-arrow-right" ></i> </button>
                                            <div className='text-center mt-3'>
                                                <Link to="/adminLogin">Already Known ? Login</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default ProvierLogin;