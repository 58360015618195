import React, { useState, useEffect } from 'react'
import Loading from './Loading'
import Header from './Header'
import Footer from './Footer'
import LoginModal from './LoginModal'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import lib from '../config/lib'

const AllCategories = () => {
    const [loading, setLoading] = useState(false);
    const [catdata, setCatdata] = useState([]);

    useEffect(() => {
        handlecatfeth();
    }, []);

    const handlecatfeth = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getCatallhome);
            setCatdata(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="page-wraper">
                <Header />
                <Loading loading={loading}/>
                <div className="page-content">
                    <div className="aon-page-benner-area2">
                        <div className="aon-banner-large2-title">
                            All Category Listing
                        </div>
                    </div>
                    <div className="aon-all-categories-wrap2">
                        <div className="container">
                            <div className="aon-all-categories-block2">
                                <div className="row">
                                    {catdata && catdata.map((item, index) => (
                                        <Link to='/CategoriesDetail' state={{ catsiaplay: item.categoryname }} className="col-lg-4 col-md-6">
                                            <div className="aon-all-cat-block" style={{ backgroundImage: `url(${lib.imgurl}/${item.categoryImg})` }}>
                                                <div className="aon-cat-quantity">
                                                    <span><i>+</i>05</span>
                                                </div>
                                                <div className="aon-cat-name">
                                                    <h3><Link to='/CategoriesDetail' state={{ catsiaplay: item.categoryname }}>{item.categoryname}</Link></h3>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
            </div>
            <LoginModal />
        </div>

    )
}

export default AllCategories