import React from 'react'
import Loading from './Loading'
import Footer from './Footer'
import Header from './Header'
import LoginModal from './LoginModal'

const CheckoutScreen = () => {
    return (
        <>
            <div>
                {/* LOADING AREA START ===== */}
                {/* <div className="loading-area">
                    <Loading />
                </div> */}
                {/* LOADING AREA  END ====== */}
                <div className="page-wraper">        {/* HEADER START */}
                    <Header />
                    {/* HEADER END */}
                    {/* Content */}
                    <div className="page-content">
                        {/* Banner Area */}
                        <div className="section-content sf-allCaty-grid-wrap sf-owl-arrow pt-5 pb-1">
                            <div className="container">
                                {/*Title Section Start*/}
                                <div className="section-head">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2 className="sf-title text-white">Checkout</h2>
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                </div>
                                {/*Title Section End*/}
                            </div>
                        </div>
                        {/* Banner Area End */}
                        {/* Left & right section */}
                        <div className="aon-blog-page-wrap">
                            <div className="container">
                                <div className="row">
                                    {/* Left part start */}
                                    <div className="col-lg-8 col-md-12">
                                        <div id="grid">
                                            {/*block 1*/}
                                            <div className="aon-blog-list5">
                                                <div className="post-bx">
                                                    <div className="bg-color-box" />
                                                    <div className="post-thum">
                                                        <img title="title" src="images/blog/blog-list2/1.jpg" alt />
                                                        <div className="post-share">
                                                            <a href="#" className="post-share-icon feather-share-2" />
                                                        </div>
                                                    </div>
                                                    <div className="post-date-position">
                                                        <div className="post-date">
                                                            <span>MAR 18,  2022</span>
                                                        </div>
                                                    </div>
                                                    <div className="post-info">
                                                        <div className="post-meta1">
                                                            <ul>
                                                                <li className="post-author"><i className="feather-user" />By
                                                                    <a href="#" title="Posts by admin" rel="author">Nina Brown</a>
                                                                </li>
                                                                <li className="post-comment"><span><i className="feather-message-square" />Comment</span></li>
                                                            </ul>
                                                        </div>
                                                        <div className="post-text">
                                                            <h4 className="post-title">
                                                                <a href="">Helping Companies in  Their Green Transition Racing </a>
                                                            </h4>
                                                            <p>Novia's spaciously two bedroom apartments are perfect for families and even business partners. Look out into the Manhattan skyline from.</p>
                                                        </div>
                                                        <div className="post-categories">
                                                            <a href="#">Logistics</a>
                                                            <a href="#">Cleaning</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Left part END */}
                                    {/* Side bar start */}
                                    <div className="col-lg-4 col-md-12">
                                        <aside className="side-bar ">
                                            {/* Social */}
                                            <div className="widget rounded-sidebar-widget">
                                                <div className="text-left m-b30">
                                                    <h3 className="widget-title">Service</h3>
                                                </div>
                                                <div className="widget_social_inks">
                                                    <li>Cart</li>
                                                </div>
                                            </div>

                                            {/* SEARCH */}
                                            <div className="widget rounded-sidebar-widget">
                                                <div className="widget_search_bx">
                                                    <div className="text-left m-b30">
                                                        <h3 className="widget-title">Coupon and offres</h3>
                                                    </div>
                                                    <li>Save 50% off on this order</li>
                                                </div>
                                            </div>
                                            {/* CATEGORY */}
                                            <div className="widget widget_services rounded-sidebar-widget">
                                                <div className="text-left m-b30">
                                                    <h3 className="widget-title">Payment summary</h3>
                                                </div>
                                                <ul>
                                                    <li><span>Item total</span><span className="badge">R. 28.00</span></li>
                                                    <li><span>Item discount</span><span className="badge">R. 05.00</span></li>
                                                    <li><span>Taxes and Fee</span><span className="badge">R. 24.00</span></li>
                                                    <hr />
                                                    <li><h4 className='d-flex justify-content-between'><span>Total</span><span>R. 57.00</span></h4></li>
                                                </ul>
                                            </div>
                                            <div className='d-flex justify-content-center'><button className="site-button">Pay R 57.00</button></div>
                                        </aside>
                                    </div>
                                    {/* Side bar END */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
                </div>
                {/* Login Sign Up Modal */}

                <LoginModal />

            </div>

        </>
    )
}

export default CheckoutScreen