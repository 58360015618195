import React, { useState } from 'react';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';
import Loading from './Loading';

const LoginModal = () => {
    const [firstName, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [cp, setCp] = useState('');
    const [address, setAddress] = useState('');

    const [loading, setLoading] = useState(false);

    const handleSignup = async () => {
        setLoading(true);

        if (pass === cp) {
            try {
                const response = await axios.post(lib.apiURL + '/' + lib.apis.signup, {
                    "firstName": firstName,
                    "lastName": lastname,
                    "email": email,
                    "phone": phone,
                    "pass": pass,
                    "address": address
                });
                if (response.data.status === "success") {
                    Swal.fire('Success', 'User Login Successfully', 'success');
                    setLoading(false);
                }
            }
            catch (err) {
                console.log(err);
                Swal.fire({
                    title: 'Failed',
                    text: err.response.data.error,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        }
        else {
            Swal.fire({
                title: 'Failed',
                text: 'Password and New password not mached',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.login, {
                "email": username,
                "pass": password,
            });
            if (response.data.status === "success") {
                localStorage.setItem('token', response.data.token);
                Swal.fire('Success', 'User Login Successfully', 'success');
                const modal = document.getElementById('login-signup-model');
                if (modal) {
                    modal.click();
                }
                window.location.reload();
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            Swal.fire({
                title: 'Failed',
                text: err.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }


    return (
        <div>
            <div className="modal fade" id="login-signup-model">
                <div className="modal-dialog">

                    <div className="modal-content">
                        <button type="button" className="close aon-login-close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <div className="sf-custom-tabs sf-custom-new aon-logon-sign-area p-3">
                                {/*Tabs*/}
                                <ul className="nav nav-tabs nav-table-cell">
                                    <li><a data-toggle="tab" href="#Upcoming" className="active">Login</a></li>
                                    <li><a data-toggle="tab" href="#Past">Sign up</a></li>
                                </ul>
                                {/*Tabs Content*/}
                                <div className="tab-content">
                                    {/*Login Form*/}
                                    <div id="Upcoming" className="tab-pane active">
                                        <div className="sf-tabs-content">
                                            <div className="aon-login-form">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="company_name" type="text" placeholder="User Name" onChange={(e) => setUsername(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-user" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="company_name" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-lock" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Loading loading={loading} />

                                                    <div className="col-md-12">
                                                        <div className="form-group d-flex aon-login-option justify-content-between">
                                                            <div className="aon-login-opleft">
                                                                <div className="checkbox sf-radio-checkbox">
                                                                    <input id="td2_2" name="abc" defaultValue="five" type="checkbox" />
                                                                    <label htmlFor="td2_2">Keep me logged</label>
                                                                </div>
                                                            </div>
                                                            <div className="aon-login-opright">
                                                            <a href='forgotpasswordcustomer'>Forgot Password</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="site-button w-100" onClick={handleLogin}>Submit <i className="feather-arrow-right" /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Sign up Form*/}
                                    <div id="Past" className="tab-pane">
                                        <div className="sf-tabs-content">
                                            <div className="aon-login-form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="First_Name" type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-user" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="company_name" type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-user" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="Phone" type="text" placeholder="Phone" onChange={(e) => setPhone(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-phone" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="email" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-envelope-o" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="email" type="text" placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-envelope-o" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="password" type="password" placeholder="Password" onChange={(e) => setPass(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-lock" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <div className="aon-inputicon-box">
                                                                <input className="form-control sf-form-control" name="password" type="password" placeholder="Confirm Password" onChange={(e) => setCp(e.target.value)} />
                                                                <i className="aon-input-icon fa fa-lock" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group sign-term-con">
                                                            <div className="checkbox sf-radio-checkbox">
                                                                <input id="td33" name="abc" defaultValue="five" type="checkbox" />
                                                                <label htmlFor="td33">I've read and agree with your <a href="#">Privacy Policy</a> and <a href="#">Terms &amp; Conditions</a> </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="site-button w-100" onClick={handleSignup}>Submit <i className="feather-arrow-right"  /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Sign start hear */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginModal