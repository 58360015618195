import React, { useEffect, useState } from "react";
import Loading from './Loading'
import Footer from './Footer'
import Header from "./Header";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import lib from "../config/lib";

const HomePage = () => {
    const [loading, setLoading] = useState(false);
    const [catdata, setCatdata] = useState([]);
    const [favrate, setFavrate] = useState([]);
    const [providerLenth, setproviderLenth] = useState('');
    const [catLength, setCatLength] = useState('');
    const [serviceLenth, setServiceLenth] = useState('');
    const [customerLenth, setCustomerLenth] = useState('');

    useEffect(() => {
        handlecatfeth();
        handleFethfav();
        getAllproviderHome();
        getuserallHome();
        serviceAreaAllhome();
    }, []);

    const handlecatfeth = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getCatallhome);
            const responseData = response.data.data;
            setCatLength(responseData.length);
            setCatdata(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const handleFethfav = async () => {
        setLoading(true);

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.providerFav + '/true');
            setFavrate(response.data.data);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const getAllproviderHome = async () => {
        setLoading(true);

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getAllproviderHome);
            const responseData = response.data.data;
            setproviderLenth(responseData.length);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const serviceAreaAllhome = async () => {
        setLoading(true);

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.serviceAreaAllhome);
            const responseData = response.data.data;
            setServiceLenth(responseData.length);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }

    const getuserallHome = async () => {
        setLoading(true);

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getuserallHome);
            const responseData = response.data.data;
            setCustomerLenth(responseData.length);
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            setLoading(false);
        }
    }


    return (
        <div>
            <div className="page-wraper">
                <Header />
                <Loading loading={loading} />
                <div className="page-content">
                    <section className="aon-banner-area2">
                        <div className="container">
                            <div className="aone-banner-area2-inner">
                                <div className="row d-flex align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                        <div className="aon-bnr2-content-wrap">
                                            <div className="aon-bnr-write">
                                                <h2 className="text-top-line">Hire
                                                    <span className="text-secondry">Experts</span> &amp;
                                                </h2>
                                                <h2 className="text-bot-line">Get Your Job Done</h2>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <div className="aon-bnr2-media-wrap">
                                            <div className="aon-bnr2-media">
                                                <img src="images/banner2/1.png" alt='' />
                                            </div>
                                            <div className="aon-bnr2-lines-left">
                                                <div className="aon-bnr2-line-left-content">
                                                    <img src="images/banner2/line-left.png" alt='' />
                                                    <span className="circle-l-1 slide-fwd-center" />
                                                    <span className="circle-l-2 slide-fwd-center2" />
                                                    <span className="circle-l-3 slide-fwd-center3" />
                                                </div>
                                            </div>
                                            <div className="aon-bnr2-lines-right">
                                                <img src="images/banner2/line-right.png" alt='' />
                                                <span className="circle-r-1 slide-fwd-center3" />
                                                <span className="circle-r-2 slide-fwd-center2" />
                                                <span className="circle-r-3 slide-fwd-center" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="bg-white aon-categories-area2">
                        <div className="container">
                            <div className="section-head">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <span className="aon-sub-title">categories</span>
                                        <h2 className="sf-title">Popular Categories</h2>
                                    </div>
                                    {/* <div className="col-lg-6 col-md-12">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className="section-content">
                                <div className="aon-categories-area2-section">
                                    <div className="row justify-content-center">
                                        {catdata && catdata.map((item, index) => (
                                            <Link to='/CategoriesDetail' state={{ catsiaplay: item.categoryname }} className="col-lg-4 col-md-6">
                                                <div className="media-bg-animate mba-bdr-15">
                                                    <div className="aon-categories-area2-iconbox aon-icon-effect">
                                                        <div className="aon-cate-area2-icon">
                                                            <span>
                                                                <i className="aon-icon"><img src={lib.imgurl + '/' + item.categoryImg} alt='' /></i>
                                                            </span>
                                                        </div>
                                                        <div className="aon-cate-area2-content">
                                                            <h4 className="aon-tilte"><Link to='/CategoriesDetail' state={{ catsiaplay: item.categoryname }}>{item.categoryname}</Link></h4>
                                                            <p>{item.categorydes}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="aon-btn-pos-center">
                                        <Link className="site-button" to={'/AllCategoryDetaile'}>View All</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="aon-howit-area2">
                        <div className="container">
                            <div className="aon-howit-area2-section">
                                <div className="aon-howit-area2-bg">
                                    {/*Title Section Start*/}
                                    <div className="section-head aon-title-center white">
                                        <span className="aon-sub-title">Stpes</span>
                                        <h2 className="sf-title">How It Work</h2>
                                    </div>
                                    {/*Title Section Start End*/}
                                    <div className="section-content">
                                        <div className="aon-categories-area2-section">
                                            <div className="row justify-content-center">
                                                {/* Block 1*/}
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="aon-howit-area2-iconbox aon-icon-effect">
                                                        <div className="aone-howit-number">01</div>
                                                        <div className="aon-howit-area2-icon">
                                                            <span>
                                                                <i className="aon-icon"><img src="images/step-icon/1.png" alt='' /></i>
                                                            </span>
                                                        </div>
                                                        <div className="aon-howit-area2-content">
                                                            <h4 className="aon-tilte">Describe Your Task</h4>
                                                            <p>This helps us determine which Taskers We are abest jobs.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Block 2*/}
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="aon-howit-area2-iconbox aon-howit-arrow aon-icon-effect">
                                                        <div className="aone-howit-number">02</div>
                                                        <div className="aon-howit-area2-icon">
                                                            <span>
                                                                <i className="aon-icon"><img src="images/step-icon/2.png" alt='' /></i>
                                                            </span>
                                                        </div>
                                                        <div className="aon-howit-area2-content">
                                                            <h4 className="aon-tilte">Choose a Tasker</h4>
                                                            <p>This helps us determine which Taskers We are abest jobs.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Block 3*/}
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="aon-howit-area2-iconbox aon-icon-effect">
                                                        <div className="aone-howit-number">03</div>
                                                        <div className="aon-howit-area2-icon">
                                                            <span>
                                                                <i className="aon-icon"><img src="images/step-icon/3.png" alt='' /></i>
                                                            </span>
                                                        </div>
                                                        <div className="aon-howit-area2-content">
                                                            <h4 className="aon-tilte">Live Smarter</h4>
                                                            <p>This helps us determine which Taskers We are abest jobs.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-full aon-feature-vender-area2">
                        <div className="container">

                            <div className="section-head">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <span className="aon-sub-title">Vendor</span>
                                        <h2 className="sf-title">Featured Providers</h2>
                                    </div>
                                </div>
                            </div>

                            <OwlCarousel
                                className="owl-theme"
                                loop
                                margin={10}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 3
                                    }
                                }}
                            >
                                {favrate && favrate.map((item, index) => (
                                    <div className="item">
                                        <div className="aon-ow-provider-wrap2"> 
                                            <div className="aon-ow-provider2 shine-hover">
                                                <div className="aon-ow-top">
                                                    <div className="aon-pro-check"><span><i className="fa fa-check" /></span></div>
                                                    <div className="aon-pro-favorite"><a href="#"><i className="fa fa-heart-o" /></a></div>
                                                    <div className="aon-ow-info">
                                                        <h4 className="sf-title"><Link to='/ProfileFull' state={{ providerid: item.id }} >{item.name}</Link></h4>
                                                        <span>{item.state} {item.country}</span>
                                                    </div>
                                                </div>
                                                <div className="aon-ow-mid">
                                                    <div className="aon-ow-media media-bg-animate">
                                                        <Link className="shine-box" to='/ProfileFull' state={{ providerid: item.id }} ><img src={lib.imgurl + '/' + item.profileImg} alt='' /></Link>
                                                    </div>
                                                </div>
                                                <div className="aon-ow-bottom">
                                                    <Link to='/ProfileFull' state={{ providerid: item.id }} className="site-button">Request A Quote</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </OwlCarousel>
                        </div>
                    </section>
                    <section className="aon-why-choose2-area">
                        <div className="container">
                            <div className="aon-why-choose2-box">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="aon-why-choose-info">
                                            <div className="section-head">
                                                <span className="aon-sub-title">Choose</span>
                                                <h2 className="aon-title">Why Choose us</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                            <ul className="aon-why-choose-steps list-unstyled">
                                                <li className="d-flex">
                                                    <div className="aon-w-choose-left aon-icon-effect">
                                                        <div className="aon-w-choose-icon"><i className="aon-icon"><img src="images/whychoose/1.png" alt='' /></i></div>
                                                    </div>
                                                    <div className="aon-w-choose-right">
                                                        <h4 className="aon-title">Meet new customers</h4>
                                                        <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                                    </div>
                                                </li>
                                                {/* COLUMNS 2 */}
                                                <li className="d-flex">
                                                    <div className="aon-w-choose-left aon-icon-effect">
                                                        <div className="aon-w-choose-icon"><i className="aon-icon"><img src="images/whychoose/2.png" alt='' /></i></div>
                                                    </div>
                                                    <div className="aon-w-choose-right">
                                                        <h4 className="aon-title">Grow your revenue</h4>
                                                        <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                                    </div>
                                                </li>
                                                {/* COLUMNS 3 */}
                                                <li className="d-flex">
                                                    <div className="aon-w-choose-left aon-icon-effect">
                                                        <div className="aon-w-choose-icon"><i className="aon-icon"><img src="images/whychoose/3.png" alt='' /></i></div>
                                                    </div>
                                                    <div className="aon-w-choose-right">
                                                        <h4 className="aon-title">Build your online reputation</h4>
                                                        <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* COLUMNS RIGHT */}
                                    <div className="col-lg-6 col-md-12">
                                        <div className="aon-why-choose2-line">
                                            <div className="aon-why-choose2-pic" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-white aon-testimonials-two-area">
                        <div className="aon-half-bg" />
                        <div className="container">
                            {/*Title Section Start*/}
                            <div className="section-head">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <span className="aon-sub-title">Testimonials</span>
                                        <h2 className="aon-title">What People Say</h2>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-content">
                                {/* <div className="owl-carousel testimonials-two-carousel-owl aon-owl-arrow"> */}
                                <OwlCarousel
                                    className="owl-carousel owl-theme "
                                    loop
                                    rtlClass="aon-owl-arrow"
                                    nav={true}
                                    margin={10}
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 3
                                        }
                                    }}

                                >
                                    {/* COLUMNS 1 */}
                                    <div className="item">
                                        <div className="aon-test2-item">
                                            <div className="aon-test2-pic"><img src="images/testimonials2/pic1.jpg" alt='' /></div>
                                            <h3 className="aon-test2-name">David Smith</h3>
                                            <div className="aon-test2-position">Web Designer</div>
                                            <div className="aon-test2-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore.</div>
                                            <div className="aon-test2-animate">
                                                <span className="aon-test2-circle1" />
                                                <span className="aon-test2-square1" />
                                                <span className="aon-test2-square2" />
                                                <span className="aon-test2-circle2" />
                                                <span className="aon-test2-plus">+</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* COLUMNS 2 */}
                                    <div className="item">
                                        <div className="aon-test2-item">
                                            <div className="aon-test2-pic"><img src="images/testimonials2/pic2.jpg" alt='' /></div>
                                            <h3 className="aon-test2-name">David Smith</h3>
                                            <div className="aon-test2-position">Web Designer</div>
                                            <div className="aon-test2-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore.</div>
                                            <div className="aon-test2-animate">
                                                <span className="aon-test2-circle1" />
                                                <span className="aon-test2-square1" />
                                                <span className="aon-test2-square2" />
                                                <span className="aon-test2-circle2" />
                                                <span className="aon-test2-plus">+</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* COLUMNS 3 */}
                                    <div className="item">
                                        <div className="aon-test2-item">
                                            <div className="aon-test2-pic"><img src="images/testimonials2/pic3.jpg" alt='' /></div>
                                            <h3 className="aon-test2-name">David Smith</h3>
                                            <div className="aon-test2-position">Web Designer</div>
                                            <div className="aon-test2-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore.</div>
                                            <div className="aon-test2-animate">
                                                <span className="aon-test2-circle1" />
                                                <span className="aon-test2-square1" />
                                                <span className="aon-test2-square2" />
                                                <span className="aon-test2-circle2" />
                                                <span className="aon-test2-plus">+</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* COLUMNS 4 */}
                                    <div className="item">
                                        <div className="aon-test2-item">
                                            <div className="aon-test2-pic"><img src="images/testimonials2/pic1.jpg" alt='' /></div>
                                            <h3 className="aon-test2-name">David Smith</h3>
                                            <div className="aon-test2-position">Web Designer</div>
                                            <div className="aon-test2-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do usmod tempor incididunt ut labore.</div>
                                            <div className="aon-test2-animate">
                                                <span className="aon-test2-circle1" />
                                                <span className="aon-test2-square1" />
                                                <span className="aon-test2-square2" />
                                                <span className="aon-test2-circle2" />
                                                <span className="aon-test2-plus">+</span>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>

                            {/* </div> */}
                        </div>
                    </section>
                    <section className="aon-statics-area2">
                        <div className="container">
                            <div className="aon-statics-area2-section">
                                <div className="aon-statics-area2-bg">
                                    {/*Title Section Start*/}
                                    <div className="section-head aon-title-center white">
                                        <span className="aon-sub-title">Statics</span>
                                        <h2 className="sf-title">Trusted by thousands of people all over the world</h2>
                                    </div>
                                    <div className="section-content">
                                        <div className="aon-statics-blocks2">
                                            <div className="row">
                                                {/* COLUMNS 1 */}
                                                <div className="col-lg-3 col-md-6 col-6">
                                                    <div className="aon-static-section2 aon-t-white2">
                                                        <div className="aon-company-static-num2 counter">{providerLenth}</div>
                                                        <div className="aon-company-static-name2">Providers</div>
                                                    </div>
                                                </div>
                                                {/* COLUMNS 2 */}
                                                <div className="col-lg-3 col-md-6 col-6">
                                                    <div className="aon-static-section2 aon-t-skyblue2">
                                                        <div className="aon-company-static-num2 counter">{catLength}</div>
                                                        <div className="aon-company-static-name2">Categories</div>
                                                    </div>
                                                </div>
                                                {/* COLUMNS 3 */}
                                                <div className="col-lg-3 col-md-6 col-6">
                                                    <div className="aon-static-section2 aon-t-yellow2">
                                                        <div className="aon-company-static-num2 counter">{serviceLenth}</div>
                                                        <div className="aon-company-static-name2">Services</div>
                                                    </div>
                                                </div>
                                                {/* COLUMNS 4 */}
                                                <div className="col-lg-3 col-md-6 col-6">
                                                    <div className="aon-static-section2 aon-t-green2">
                                                        <div className="aon-company-static-num2 counter">{customerLenth}</div>
                                                        <div className="aon-company-static-name2">Customer</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="aon-newsletter-area2">
                        <div className="container">
                            <div className="aon-newsletter-area2-section">
                                <h3 className="aon-title">We empower clients to grow their businesses based on the effective use of technology</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. lorem Ipsum has been the standard dummy text ever since the 1500s, when.</p>
                                <form className="aon-nl-width">
                                    <div className="form-group sf-news-l-form">
                                        <input type="text" className="form-control" placeholder="Enter Your Email" />
                                        <button type="submit" className="sf-sb-btn">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section> */}
                </div>
                <Footer />
                <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>
            </div>
            {/* Login Sign Up Modal */}
            <LoginModal />
        </div>

    )
};

export default HomePage;