import React, { useEffect, useState } from 'react';
import VendorSidebar from '../Provider/VendorSideBar';
import handleVerify from '../config/accountvalidation';
import { useNavigate } from 'react-router-dom';
import ManuButton from './ManuButton';
import Swal from 'sweetalert2';
import axios from 'axios';
import lib from '../config/lib';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function MyBookingsAdmin() {
    const [loading, setLoading] = useState(false);
    const [datapending, setDatapending] = useState([]);
    const [datacomplete, setDatacomplete] = useState([]);

    const [amount, setAmount] = useState('');

    const [providercomming, setProvidercomming] = useState('false');
    const router = useNavigate();

    const role = localStorage.getItem('role');

    useEffect(() => {
        if (role === "emp") {
            handleVerify(router);
            handleFethBookingcomplete();
            handleFethBooking();
        }
        else {
            if (role === "superadmin") {
                handleFethDataAll();
                handleFethDataAllpending();
            }
        }

    }, []);

    const [isButtonActive, setButtonActive] = useState(false);

    const handleManu = () => {
        setButtonActive(!isButtonActive);
    };

    const handleFethBooking = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getBookingbyprovider + '/Incomplete', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setDatapending(response.data.data);
            setProvidercomming("true");
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleFethBookingcomplete = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getBookingbyprovider + '/Completed', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setDatacomplete(response.data.data);
            setProvidercomming("true");
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }


    const handleFethDataAll = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getAllbookings + '/Completed', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setDatacomplete(response.data.data);
            setProvidercomming("false");
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleFethDataAllpending = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(lib.apiURL + '/' + lib.apis.getAllbookings + '/Incomplete', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setDatapending(response.data.data);
            setProvidercomming("false");
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleComplete = async (id) => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.getApproved, {
                "id": id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setDatapending(response.data.data);
            setProvidercomming("false");
            handleFethBookingcomplete();
            handleFethBooking();
            setLoading(false);
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const [paymentid, setPaymentId] = useState('');

    const handleUpdatepayment = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.getUpdatepayment, {
                "id": paymentid,
                "payment": amount
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.status === "success") {
                Swal.fire('success', 'Payment Details Updated Successfully', 'success');
                setProvidercomming("false");
                handleFethBookingcomplete();
                handleFethBooking();
                setLoading(false);
            }
        }
        catch (error) {
            console.log('Somthing Went Wrong');
            Swal.fire({
                title: 'Failed',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleIdsave = (id) => {
        setPaymentId(id);
    }

    return (
        <>
            <VendorSidebar />
            <div id="content" className={isButtonActive ? 'active' : ''}>
                 <div className="content-admin-main">
                    <ManuButton onClick={handleManu} />
                    <div className="aon-admin-heading">
                        <h4>My Booking</h4>
                    </div>
                    <Tabs
                        defaultActiveKey="Pending"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="Pending" title="Pending">
                            <div className="card aon-card">
                                <div className="card-body aon-card-body">
                                    {/* <!-- Week Tabs--> */}
                                    <div className="sf-availability-times-tab m-b50">
                                        <div className="sf-custom-tabs sf-custom-new">
                                            {/* <!--Tabs Content--> */}
                                            <div className="tab-content">

                                                {/* <!--Upcoming--> */}
                                                <div id="Upcoming" className="tab-pane active">
                                                    <div className="sf-tabs-content">
                                                        <div className="sf-bs-data-table">
                                                            <div className="table-responsive">
                                                                {datapending && datapending.map((item, index) => (
                                                                    <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Booking Info</th>
                                                                                <th>Payment Info</th>
                                                                                <th>Status</th>
                                                                                {providercomming === "true" ? (
                                                                                    <th>Action</th>
                                                                                ) : (
                                                                                    <></>
                                                                                )}

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="sf-booking-info-col">
                                                                                            <span className="sf-booking-refid">{item.id}</span>
                                                                                            <span className="booking-status sf-booking-incomplete">{item.status}</span>
                                                                                            <div className="sf-booking-upcoming">
                                                                                                {item.quationstatus}
                                                                                            </div>
                                                                                            <div className="sf-booking-customer">
                                                                                                <ul className="customer-info">
                                                                                                    {providercomming === "true" ? (
                                                                                                        <>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Name</strong>{item.User.firstName} {item.User.lastName}</li>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Email</strong>{item.User.email}</li>
                                                                                                            <li><strong><i className="fa fa-phone"></i> Customer Phone</strong>{item.User.phone}</li>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Address</strong>{item.User.address}</li>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Name</strong>{item.User.firstName} {item.User.lastName}</li>
                                                                                                            <li><strong><i className="fa fa-phone"></i> Customer Phone</strong>{item.User.phone}</li>
                                                                                                            <li><strong><i className="fa fa-user"></i> Provider Name</strong>{item.Provider.name} {item.User.lastName}</li>
                                                                                                            <li><strong><i className="fa fa-phone"></i> Provider Phone</strong>{item.Provider.phone}</li>
                                                                                                        </>
                                                                                                    )}
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="inner">
                                                                                            <h3>
                                                                                                {item.payment === "pending" ? (
                                                                                                    <span className="sf-booking-payment-info" data-toggle="popover" data-container="body" data-placement="top" data-html="true" id="payinfo-1115" data-trigger="hover" data-original-title="" title="">$0</span>
                                                                                                ) : (
                                                                                                    <span className="sf-booking-payment-info" data-toggle="popover" data-container="body" data-placement="top" data-html="true" id="payinfo-1115" data-trigger="hover" data-original-title="" title="">${item.payment}</span>
                                                                                                )}
                                                                                                <span className="sf-payment-status">{item.paymentstatus}</span>
                                                                                            </h3>
                                                                                            <div id="popover-content-payinfo-1115" className="hide sf-pop-hide">
                                                                                                <ul className="list-unstyled margin-0 booking-payment-data">
                                                                                                    <li><strong>Total Amount:</strong> 85.00$</li>
                                                                                                    <li><strong>Providers Fee:</strong> 57.00$</li>
                                                                                                    <li><strong>Admin Fee:</strong> 28.00$</li>
                                                                                                    <li><strong>Payment Method:</strong> </li>
                                                                                                    <li><strong>Admin pay to providers:</strong> Pending</li>
                                                                                                    <li><strong>Tranaction ID:</strong> NA</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.status}
                                                                                    </td>

                                                                                    {providercomming === "true" ? (
                                                                                        <td >
                                                                                            <button className='btn btn-warning' data-toggle="modal" data-target="#add_services" type="button" onClick={() => handleIdsave(item.id)}>Update quatation</button>
                                                                                            <button className='btn btn-warning m-l10' onClick={() => handleComplete(item.id)}>Complete</button>
                                                                                        </td>
                                                                                    ) : (
                                                                                        <></>
                                                                                    )}
                                                                                </tr>
                                                                            </>
                                                                        </tbody>
                                                                    </table>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Complete" title="Complete">
                            <div className="card aon-card">
                                <div className="card-body aon-card-body">
                                    {/* <!-- Week Tabs--> */}
                                    <div className="sf-availability-times-tab m-b50">
                                        <div className="sf-custom-tabs sf-custom-new">
                                            {/* <!--Tabs Content--> */}
                                            <div className="tab-content">

                                                {/* <!--Upcoming--> */}
                                                <div id="Upcoming" className="tab-pane active">
                                                    <div className="sf-tabs-content">
                                                        <div className="sf-bs-data-table">
                                                            <div className="table-responsive">
                                                                {datacomplete && datacomplete.map((item, index) => (
                                                                    <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Booking Info</th>
                                                                                <th>Payment Info</th>
                                                                                <th>Status</th>
                                                                                {/* <th>Action</th> */}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="sf-booking-info-col">
                                                                                            <span className="sf-booking-refid">{item.id}</span>
                                                                                            <span className="booking-status sf-booking-incomplete">{item.status}</span>
                                                                                            <div className="sf-booking-upcoming">
                                                                                                {item.quationstatus}
                                                                                            </div>
                                                                                            <div className="sf-booking-customer">
                                                                                                <ul className="customer-info">
                                                                                                    {providercomming === "true" ? (
                                                                                                        <>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Name</strong>{item.User.firstName} {item.User.lastName}</li>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Email</strong>{item.User.email}</li>
                                                                                                            <li><strong><i className="fa fa-phone"></i> Customer Phone</strong>{item.User.phone}</li>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Address</strong>{item.User.address}</li>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <li><strong><i className="fa fa-user"></i> Customer Name</strong>{item.User.firstName} {item.User.lastName}</li>
                                                                                                            <li><strong><i className="fa fa-phone"></i> Customer Phone</strong>{item.User.phone}</li>
                                                                                                            <li><strong><i className="fa fa-user"></i> Provider Name</strong>{item.Provider.name} {item.User.lastName}</li>
                                                                                                            <li><strong><i className="fa fa-phone"></i> Provider Phone</strong>{item.Provider.phone}</li>
                                                                                                        </>
                                                                                                    )}

                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="inner">
                                                                                            <h3>
                                                                                                {item.payment === "pending" ? (
                                                                                                    <span className="sf-booking-payment-info" data-toggle="popover" data-container="body" data-placement="top" data-html="true" id="payinfo-1115" data-trigger="hover" data-original-title="" title="">$0</span>
                                                                                                ) : (
                                                                                                    <span className="sf-booking-payment-info" data-toggle="popover" data-container="body" data-placement="top" data-html="true" id="payinfo-1115" data-trigger="hover" data-original-title="" title="">${item.payment}</span>
                                                                                                )}
                                                                                                <span className="sf-payment-status">{item.paymentstatus}</span>
                                                                                            </h3>
                                                                                            <div id="popover-content-payinfo-1115" className="hide sf-pop-hide">
                                                                                                <ul className="list-unstyled margin-0 booking-payment-data">
                                                                                                    <li><strong>Total Amount:</strong> 85.00$</li>
                                                                                                    <li><strong>Providers Fee:</strong> 57.00$</li>
                                                                                                    <li><strong>Admin Fee:</strong> 28.00$</li>
                                                                                                    <li><strong>Payment Method:</strong> </li>
                                                                                                    <li><strong>Admin pay to providers:</strong> Pending</li>
                                                                                                    <li><strong>Tranaction ID:</strong> NA</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.status}
                                                                                    </td>
                                                                                    {/* <td >
                                                                                        <button className='btn btn-warning'>Update quatation</button>
                                                                                        <button className='btn btn-warning m-l10'>Complete</button>
                                                                                    </td> */}
                                                                                </tr>
                                                                            </>
                                                                        </tbody>
                                                                    </table>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                </div>
            </div>

            {/* Update Amount Modal */}

            <div className="modal fade content-admin-main" id="add_services" tabindex="-1" role="dialog" ariaHidden="true" >
                    <div className="modal-dialog model-w800" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel2">Update Amount</h5>
                                <button type="button" className="close" data-dismiss="modal" ariaLabel="Close">
                                    <span ariaHidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="sf-md-padding">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input className="form-control sf-form-control" name="number" type="number" placeholder="Enter Amount" onChange={(e) => setAmount(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="admin-button" data-dismiss="modal">Cancel</button>
                                <button type="button" className="admin-button" onClick={handleUpdatepayment}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>

            {/* < !--Modal add group-- > */}
            < div className="modal fade content-admin-main" id="downloadreport" tabindex="-1" role="dialog" ariaLabelledby="exampleModalLabel" ariaHidden="true" >
                <div className="modal-dialog model-w800" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Select Range</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="sf-md-padding">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Select Range</label>
                                            <div className="aon-inputicon-box">
                                                <input className="form-control sf-form-control" name="company_name" type="text" />
                                                <i className="aon-input-icon fa fa-calendar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="admin-button" data-dismiss="modal">Cancel</button>
                            <button type="button" className="admin-button">Apply</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default MyBookingsAdmin;